export const Pages = {
    root: {
        title: () => 'Endless Presta',
        path: () => '/',
    },
    login: {
        title: () => 'Se connecter - Endless Presta',
        path: () => '/se-connecter',
    },
    toDo: {
        title: () => 'Prestations à faire',
        path: () => '/prestations/a-faire',
    },
    toCome: {
        title: () => 'Prestations à venir',
        path: () => '/prestations/a-venir',
    },
    toValidate: {
        title: () => 'Prestations à valider',
        path: () => '/prestations/a-valider',
    },
    history: {
        title: () => 'Historique des prestations',
        path: () => '/prestations/historique',
    },
    ccReport: {
        title: (order_number?: string | null) => order_number
            ? `Rapport de prestation - ${order_number}`
            : 'Rapport de prestation',
        path: (id: string) => `prestations/${id}/rapport`,
    },
};
