import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";

import { useInitiateOtp } from "@/hooks/initiate-otp";
import { useValidateOtp } from "@/hooks/validate-otp";

const InitiateOtpFormSchema = z.object({
    email: z.string().email('Email invalide')
});

const ValidateOtpFormSchema = z.object({
    code: z.string().length(6).regex(/^\d+$/, 'Code invalide')
});

type Step = 'initiate' | 'validate';

type UseLoginProps = {
    onLoginSuccess?: () => void;
}

export const useLogin = (props: UseLoginProps) => {
    const [step, setStep] = React.useState<Step>('initiate');

    const initiate = useInitiateOtp({
        onSuccess: () => {
            setStep('validate');
        }
    });

    const validate = useValidateOtp({
        onSuccess: () => {
            props.onLoginSuccess?.();
        }
    });

    const initiateOtpForm = useForm<z.infer<typeof InitiateOtpFormSchema>>({
        resolver: zodResolver(InitiateOtpFormSchema),
        defaultValues: { email: '' },
    });

    const validateOtpForm = useForm<z.infer<typeof ValidateOtpFormSchema>>({
        resolver: zodResolver(ValidateOtpFormSchema),
        defaultValues: { code: '' },
    });

    const reset = () => {
        initiateOtpForm.reset();
        validateOtpForm.reset();
        setStep('initiate');
    };

    return {
        step,
        reset,
        initiate: {
            api: initiate,
            form: initiateOtpForm
        },
        validate: {
            api: validate,
            form: validateOtpForm,
        },
        isPending: initiate.isPending || validate.isPending,
    };
};

