import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { StatusCodes } from 'http-status-codes';
import { ulid } from 'ulid';

import { httpClient } from '@/lib/axios';
import { ndlssEndpoints } from '@/lib/ndlssEndpoints';
import { queryKey } from '@/lib/query';
import { DocumentCreateDto, EDocumentType, EFileMimeTypes, GenerateUploadUrlDto, IBbngResponse } from '@/types/global';
import axios from 'axios';

export type NdlssFile = {
    file: File;
    type: EDocumentType;
    name: string;
    customKey: string;
};
export const useUploadFiles = () => {
    const [progress, setProgress] = React.useState<number>(0);
    const mutation = useMutation({
        mutationKey: [queryKey.files.upload],
        mutationFn: async (ctx: NdlssFile[]) => {
            const upload = async (data: NdlssFile): Promise<DocumentCreateDto> => {
                const mimeType = data.file.type;
                const fileId = customFileId(data.type, mimeType, data.customKey);

                const { data: { data: { ro: signedUrl } } } = await httpClient.post<IBbngResponse<string>>(
                    ndlssEndpoints.documents.createSignedUrl(),
                    <GenerateUploadUrlDto>{
                        fileId,
                        mimeType,
                    }
                );

                if (!signedUrl) {
                    throw new Error('Failed to create signed url');
                }

                const res = await axios.put(signedUrl, data.file);

                if (res.status !== StatusCodes.OK) {
                    throw new Error('Failed to upload file');
                }

                return {
                    id: fileId,
                    mime_type: mimeType,
                    name: data.name,
                    type: data.type,
                    size: data.file.size,
                }
            };

            const dtos: DocumentCreateDto[] = [];

            for(let i = 0; i < ctx.length; i++) {
                const dto = await upload(ctx[i]);
                dtos.push(dto);
                setProgress(Math.floor(((i + 1) / ctx.length) * 100));
            }

            return dtos;
        },
    })

    return {
        upload: (files: NdlssFile[], params: Parameters<typeof mutation.mutate>[1] = {}) => {
            setProgress(0);
            mutation.mutate(files, params);
        },
        mutation,
        progress,
    }
};

const customFileId = (type: EDocumentType, mimetype: string, customKey: string): string => {
    //extract extension from mimetype
    const getExtension = (mimetype: string) => {
        switch (mimetype) {
            case EFileMimeTypes.PDF:
                return '.pdf';
            case EFileMimeTypes.JPG:
                return '.jpeg';
            case EFileMimeTypes.PNG:
                return '.png';
            default:
                return '.txt';
        }
    };

    return `${type}|${ulid()}|${customKey}${getExtension(mimetype)}`;
};
