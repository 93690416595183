import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { toast } from "sonner";
import { Helmet } from "react-helmet";

import { ReportCCForm } from "@/forms/report-cc";
import { useCollectCreate } from "@/hooks/collects";
import { LoaderDialog } from "@/components/loader-dialog";
import { useCollectConfig } from "@/hooks/collect-configs";
import { useUploadFiles } from "@/hooks/files";
import { Progress } from "@/components/ui/progress";
import { Pages } from "@/lib/pages";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Copy, MapPin, User } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { CCBadgeCategory } from "@/components/cc-badge-category";

type ReportCCProps = {
  ccId?: string
};
export const ReportCC: React.FC<ReportCCProps> = (props) => {
  const { id = '' } = useParams();
  const nav = useNavigate();
  const query = useCollectConfig({ id: props.ccId ?? id })
  const cc = query.data?.data?.ro;
  const mutation = useCollectCreate({
    options: {
      onSuccess: () => {
        toast.success(cc
          ? `Le rapport de prestation ${cc.order_number} a été créé`
          : 'Le rapport de prestation a été créé'
        );
        nav(Pages.toDo.path());
      }
    }
  });
  const filesUploader = useUploadFiles();

  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${cc?.address.coordinates.latitude}%2C${cc?.address.coordinates.longitude}`;

  return (
    <>
      <Helmet>
        <title>{Pages.ccReport.title()}</title>
      </Helmet>
      <div className="p-4 m-auto max-w-7xl">
        <LoaderDialog show={query.isLoading} info={'Récupération des données...'} />
        <LoaderDialog
          enableMask
          show={mutation.isPending || filesUploader.mutation.isPending}
          info={() => {
            if (mutation.isPending) {
              return 'Création du rapport...';
            }
            if (filesUploader.mutation.isPending) {
              return (
                <div className="flex flex-col items-center gap-4">
                  <p className="font-lg font-bold text-center">Téléchargement des images...</p>
                  <Progress value={filesUploader.progress} />
                </div>
              );
            }
            return 'Chargement...';
          }}
        />

        <h1 className="text-2xl font-bold text-center mb-4 mt-2">
          Rapport de prestation
        </h1>
        {cc && (
          <div className="flex justify-center gap-2 mb-2">
            <CCBadgeCategory family={cc.family} />
            <Badge variant={"secondary"}>
              {cc?.order_number}
            </Badge>
          </div>
        )}
        {cc && (
          <>
            <Card className="p-4">
              <h2 className="text-lg font-bold mb-2">Information de mission</h2>
              <div className="pl-2">
                <p>Contact Chantier</p>
                {cc.construction_site_contact.length > 0 ? (
                  <div className="pl-2">
                    {cc.construction_site_contact.map((contact, index) => {
                      const name = [contact.firstname, contact.lastname].filter(Boolean).join(' ');
                      const phone = contact.phone_number;
                      return (
                        <div key={index + contact.phone_number} className={"flex items-center gap-2"}>
                          <User size="1rem" />
                          <p><small>{name}</small></p>
                          <Link to={`tel:${phone}`} className="font-bold underline underline-offset-2"><small>{phone}</small></Link>
                          <Button size={"icon"} variant={'outline'} onClick={() => navigator.clipboard.writeText(phone)}>
                            <Copy size="1rem" className="ml-1" />
                          </Button>
                        </div>
                      );
                    })}
                    <p></p>
                  </div>
                ) : (
                  <p className="pl-2 border-l-2 italic"><small>Aucun contact renseigné</small></p>
                )}
              </div>
              <div className="pl-2">
                <p>Adresse de mission</p>
                <div className="pl-2 flex items-center font-bold underline underline-offset-2 mb-1 gap-2">
                  <MapPin size="1rem" />
                  <Link to={mapsUrl} target="_blank" rel="noopener noreferrer">
                    <small>{cc.address.formatted_name}</small>
                  </Link>
                  <Button size={"icon"} variant={'outline'} onClick={() => navigator.clipboard.writeText(cc.address.formatted_name)}>
                    <Copy size="1rem" />
                  </Button>
                </div>
                {cc.comment && cc.comment !== '' && (
                  <div className="mt-1">
                    <p>Commentaire</p>
                    <div className="pl-2 border-l-2">
                      <small>{cc.comment}</small>
                    </div>
                  </div>
                )}
              </div>
            </Card>
            <h2 className="text-lg font-bold mt-2">Commande client</h2>
              <div className="pl-2">
                <Card className="p-2 pl-4 flex flex-col gap-2">
                  {cc.products.map((product, index) => {
                      return (
                          <div key={index} className="flex items-center gap-2 text-sm">
                              <p>{product.quantity}</p>
                              <p>x</p>
                              <p>{product.name}</p>
                          </div>
                      );
                  })}
                </Card>
              </div>
          </>
        )}
        <Separator className="my-6" />
        {cc && (
          <ReportCCForm
            cc={cc}
            isMutating={mutation.isPending || filesUploader.mutation.isPending}
            onValid={(dto, files) => {
              filesUploader.upload(files, {
                onSuccess: (data) => {
                  // add documents upload to dto
                  dto.documents = data;
                  mutation.mutate(dto);
                }
              });
            }}
          />
        )}
      </div>
    </>
  );
};
