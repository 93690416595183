import { ECollectHazardReason } from "@/types/global";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitErrorHandler, SubmitHandler, UseFormProps, useForm } from "react-hook-form";
import { z } from "zod";

const ReportCCFormSchema = z.object({
    is_finished: z.boolean(),
    hazard_reason: z.nativeEnum(ECollectHazardReason).optional(),
    hazard_comment: z.string().optional(),
    scheduled_at: z.date().max(new Date(), { message: "La date ne peut pas être dans le futur" }),
    started_at: z.date(),
    ended_at: z.date(),
    cart: z.array(
        z.object({
            id: z.string(),
            quantity: z.number().min(1),
        }).catchall(z.any()),
    ),
    files: z.array(z.instanceof(File)),
    delivery_number: z.string().optional(),
}).refine((data) => {
    if (data.is_finished) {
        if (!data.cart || data.cart.length < 1) {
            return false;
        }
    }
    return true;
}, {
    message: "Vous devez ajouter au moins un produit",
    path: ["cart"],
}).refine((data) => {
    if (!data.is_finished && !data.hazard_reason) {
        return false;
    }
    return true;
}, {
    message: "Vous devez renseigner un motif d'échec",
    path: ["hazard_reason"],
}).refine((data) => {
    if (data.started_at > data.ended_at) {
        return false;
    }
    return true;
}, {
    message: "L'heure de fin doit être après l'heure de début",
    path: ["ended_at"],
});

export type ReportCCFieldValues = z.infer<typeof ReportCCFormSchema>;

export type UseReportCcFormProps = {
    initialValues: UseFormProps<ReportCCFieldValues>['defaultValues'];
    onValid: SubmitHandler<ReportCCFieldValues>;
    onInvalid?: SubmitErrorHandler<ReportCCFieldValues>;
};

export const useReportCcForm = (props: UseReportCcFormProps) => {
    const form = useForm<ReportCCFieldValues>({
        resolver: zodResolver(ReportCCFormSchema),
        defaultValues: props.initialValues,
        mode: 'onSubmit',
    });

    return {
        form,
        submit: form.handleSubmit(props.onValid, props.onInvalid),
    };
};
