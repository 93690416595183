import { useQuery } from "@tanstack/react-query";

import { httpClient } from "@/lib/axios";
import { ndlssEndpoints } from "@/lib/ndlssEndpoints";
import { queryKey } from "@/lib/query";
import { IBbngResponse, ProductQuery, ProductRo } from "@/types/global";

export type UseProductsProps = {
    params: ProductQuery;
};

export const useProducts = (props: UseProductsProps) => {
    const query = useQuery({
        queryKey: [queryKey.cc.get, props.params] as const,
        queryFn: async (ctx) => {
            const params = ctx.queryKey[1];

            const response = await httpClient.get<IBbngResponse<ProductRo[]>>(ndlssEndpoints.products.getAll(), {
                params,
                signal: ctx.signal,
            });

            return response.data;
        },
    });

    return query;
};

export type UseProductProps = {
    id: string;
};
export const useProduct = (props: UseProductProps) => {
    const query = useQuery({
        queryKey: [queryKey.cc.get, props.id] as const,
        queryFn: async (ctx) => {
            const id = ctx.queryKey[1];

            const response = await httpClient.get<IBbngResponse<ProductRo>>(ndlssEndpoints.products.getById(id), {
                signal: ctx.signal,
            });

            return response.data;
        },
    });

    return query;
};
