import * as Sentry from '@sentry/react';
export * as Sentry from '@sentry/react';

import { env } from './env';

const release = `presta@${env.VITE_SENTRY_ENVIRONMENT}-${env.VITE_APP_VERSION}`;

const enabled = !!env.VITE_SENTRY_DSN && env.VITE_SENTRY_ENVIRONMENT !== 'local';
if (!enabled) {
    console.log('sentry - disabled');
}

export const initSentry = () => {
    Sentry.init({
        dsn: env.VITE_SENTRY_DSN,
        release: release,
        debug: env.VITE_SENTRY_DEBUG,
        environment: env.VITE_SENTRY_ENVIRONMENT,
        enabled : enabled,
        integrations: [
            Sentry.browserTracingIntegration({
                tracePropagationTargets: ["localhost", env.VITE_NDLSS_API_URL]
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [env.VITE_NDLSS_API_URL],
                maxReplayDuration: 10 * 60 * 1000, // max 10 minutes of replay duration
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.1, //  Capture 10% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", env.VITE_NDLSS_API_URL],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        /**
         *
         * @param event The error or message event generated by the SDK.
         * @param hint Event metadata useful for processing.
         * @returns A new event that will be sent | null.
         */
        beforeSend(event, hint) {
            // skip axios cancellation error, it is an expected error from aborting a request, should not be sent to Sentry
            if (event.message === 'CanceledError') {
                return null;
            }

            return event;
        },
    });
};
