export const arrayToMap = <T extends Record<string, any> = any, Key extends keyof T = any>(
    array: T[],
    key: Key
): Record<T[Key], T> => {
    if (!Array.isArray(array)) {
        return {} as Record<T[Key], T>;
    }

    const map = array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc;
    }, <Record<T[Key], T>>{});

    return map;
};
