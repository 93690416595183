import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';

import { Pages } from '@/lib/pages';
import { queryClient } from '@/lib/query';
import { AuthProvider } from '@/contexts/auth';
import { Login } from '@/pages/login';
import { Private } from '@/pages/private';
import { ToDo } from '@/pages/to-do';
import { Listings } from '@/pages/listings';
import { ToCome } from '@/pages/to-come';
import { ReportCC } from '@/pages/report';
import { History } from '@/pages/history';
import { AppVersion } from '@/components/app-version';
import { Toaster } from '@/components/ui/sonner';

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Helmet>
            <title>{Pages.root.title()}</title>
          </Helmet>
          <Routes>
            <Route element={<Private />}>
              <Route path={Pages.ccReport.path(':id')} element={<ReportCC />} />
              <Route path={Pages.history.path()} element={<History />} />
              <Route element={<Listings />}>
                <Route path={Pages.toDo.path()} element={<ToDo />} />
                <Route path={Pages.toCome.path()} element={<ToCome />} />
              </Route>
            </Route>
            <Route path={Pages.login.path()} element={<Login />} />
            <Route path="*" element={<Navigate to={Pages.toDo.path()} />} />
          </Routes>
          <AppVersion />
          <Toaster />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App
