import React from "react";
import { Drawer, DrawerContent, DrawerHeader, DrawerFooter } from "@/components/ui/drawer";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useFocusDrawer } from "@/hooks/drawers";
import { cn } from "@/lib/utils";
import { ProductInCCOrCO } from "@/types/global";
import { MinusIcon, PlusIcon } from "lucide-react";

type EditProductDrawerProps = {
    onSubmit: (product: ProductInCCOrCO) => void,
    drawer: ReturnType<typeof useFocusDrawer<ProductInCCOrCO>>,
};
export const EditProductDrawer: React.FC<EditProductDrawerProps> = ({ drawer, ...props }) => {
    const [quantity, setQuantity] = React.useState<number>(0);

    React.useEffect(() => {
        if (drawer.focused && drawer.isOpen) {
            setQuantity(drawer.focused.quantity);
        }
    }, [drawer.focused, drawer.isOpen])

    return (
        <Drawer open={drawer.isOpen} onClose={drawer.close}>
            <DrawerContent onInteractOutside={drawer.close}>
                <DrawerHeader>
                    <h2 className="text-xl font-bold">Edition du produit</h2>
                </DrawerHeader>
                <div className="px-4 text-center">
                    {drawer.focused && (
                        <div>
                            {drawer.focused.name}
                        </div>
                    )}
                </div>
                <div className="flex items-center justify-between space-x-2 py-2 px-6 max-w-full w-[300px] self-center">
                    <Button
                        variant="outline"
                        size="icon"
                        className="h-8 w-8 shrink-0 rounded-full"
                        onClick={() => setQuantity(quantity - 1)}
                        disabled={quantity <= 0}
                    >
                        <MinusIcon className="h-4 w-4" />
                        <span className="sr-only">Retirer</span>
                    </Button>
                    <Input
                        className={cn(
                            "border-transparent text-7xl h-32 w-32 text-center font-bold",
                            quantity > 99 ? "text-5xl" : "",
                        )}
                        type="number"
                        min={0}
                        value={quantity.toString()}
                        onChange={(e) => {
                            let val = parseInt(e.target.value);
                            val = isNaN(val) ? 0 : val;
                            val = val < 0 ? 0 : val;
                            val = val > 999 ? 999 : val;
                            setQuantity(val);
                        }}
                    />
                    <Button
                        variant="outline"
                        size="icon"
                        className="h-8 w-8 shrink-0 rounded-full"
                        disabled={quantity >= 999}
                        onClick={() => setQuantity(quantity + 1)}
                    >
                        <PlusIcon className="h-4 w-4" />
                        <span className="sr-only">Rajouter</span>
                    </Button>
                </div>
                <DrawerFooter>
                    <Button
                        className="mx-auto w-full max-w-xl"
                        variant={quantity === 0 ? "destructive" : "default"}
                        disabled={!drawer.focused || quantity === drawer.focused.quantity}
                        onClick={() => {
                            if (drawer.focused) {
                                props.onSubmit({ ...drawer.focused, quantity });
                                drawer.close();
                            }
                        }}
                    >
                        {quantity === 0 ? "Supprimer" : "Enregistrer"}
                    </Button>
                    <Button
                        className="mx-auto w-full max-w-xl"
                        variant={"outline"}
                        disabled={!drawer.focused}
                        onClick={() => {
                            drawer.close();
                        }}
                    >
                        Fermer
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};
