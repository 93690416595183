import React from "react";

import { useCollects } from "@/hooks/collects";
import { UseRetrieveCollectConfigs } from "@/hooks/collect-configs";
import { CC_STATUS, PlanningShiftStepCategory, type CollectQuery, type CollectService, type CollectServiceFront } from "@/types/global";

export const useCollectHistory = () => {
    const [minDate, setMinDate] = React.useState<Date | undefined>(undefined);
    const [maxDate, setMaxDate] = React.useState<Date | undefined>(undefined);
    const [search, setSearch] = React.useState<string | undefined>(undefined);
    const [status, setStatus] = React.useState<CC_STATUS | ''>('');

    const [pagination, setPagination] = React.useState<{ pageIndex: number, pageSize: number }>({
        pageIndex: 0,
        pageSize: 10,
    });

    React.useEffect(() => {
        if (pagination.pageIndex === 0) {
            return;
        }

        setPagination(prev => ({
            pageIndex: 0,
            pageSize: prev.pageSize,
        }));
    }, [minDate, maxDate, search, status, pagination.pageSize]);

    const params: CollectQuery = React.useMemo<CollectQuery>(() => ({
            min_date: minDate?.toISOString(),
            max_date: maxDate?.toISOString(),
            orderByCollectDateDesc: true,
            category: PlanningShiftStepCategory.SERVICE,
            status: status === '' ? undefined : status as CollectQuery['status'],
            statuses: status === '' ? [CC_STATUS.FINISHED, CC_STATUS.HAZARD] : undefined,
            limit: pagination.pageSize,
            page: pagination.pageIndex + 1,
            q: search && search.length >= 3 ? search : undefined,
        }), [minDate, maxDate, search, pagination, status]);

    const collectsQuery = useCollects({
        params,
    });

    const ccRetrieveQuery = UseRetrieveCollectConfigs({
        ids: collectsQuery.data?.data?.ro
            ?.map(collect => collect.informations.step_category === PlanningShiftStepCategory.SERVICE ? collect.informations.collect_config_id : '')
            .filter(s => s !== '') ?? []
    });

    const data: CollectServiceFront[] = React.useMemo(() => {
        if (collectsQuery.data && ccRetrieveQuery.data) {
            const collects = collectsQuery.data.data.ro;
            const ccRetrieve = ccRetrieveQuery.data.data.ro;

            return collects?.filter((co): co is CollectService => co.informations.step_category === PlanningShiftStepCategory.SERVICE)?.map(co => {
                return {
                    ...co,
                    informations: {
                        ...co.informations,
                        collect_config_id: ccRetrieve?.find(cc => cc.id === co.informations.collect_config_id)
                            ?? co.informations.collect_config_id
                    }
                }
            }) ?? [];
        } else {
            return [];
        }
    }, [collectsQuery, ccRetrieveQuery]);

    const [total, setTotal] = React.useState<number>();

    React.useEffect(() => {
        if (collectsQuery.data && collectsQuery.data.data?.count !== undefined) {
            const count = collectsQuery.data.data.count;

            if (count !== total) {
                setTotal(count);
            }
        }
    }, [collectsQuery.data]);

    return {
        collectsQuery: collectsQuery,
        ccRetrieveQuery: ccRetrieveQuery,
        isFetching: collectsQuery.isFetching || ccRetrieveQuery.isFetching,
        data: data,
        totalCount: total,
        minDate: {
            value: minDate,
            set: setMinDate,
        },
        maxDate: {
            value: maxDate,
            set: setMaxDate,
        },
        status: {
            value: status,
            set: setStatus,
        },
        search: {
            value: search,
            set: setSearch,
        },
        pagination: {
            value: pagination,
            set: setPagination,
        },
    };
};
