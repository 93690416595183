import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from "react-router-dom";
import React from "react";

import { CollectConfigCard, CollectConfigCardSkeleton } from "@/components/collect-config-card";
import { fns, fns_locale } from "@/lib/fns";
import { capitalize } from "@/lib/humanize-map";
import { Button } from "@/components/ui/button";
import { NoMoreContent } from "@/components/no-more-content";
import { cn } from "@/lib/utils";
import { CCInfo } from "@/components/cc-info";
import { Drawer, DrawerContent, DrawerHeader, DrawerFooter } from "@/components/ui/drawer";
import { Sheet, SheetContent, SheetHeader, SheetFooter } from "@/components/ui/sheet";

import { useToDo } from "./data";
import { Helmet } from 'react-helmet';
import { Pages } from '@/lib/pages';

export const ToDo = () => {
    const nav = useNavigate();
    const { data, query, drawer } = useToDo();
    const isMediaSmall = useMediaQuery('(max-width: 1280px)');

    const Container = React.useMemo(() => {
        return {
            Base: isMediaSmall ? Drawer : Sheet,
            Header: isMediaSmall ? DrawerHeader : SheetHeader,
            Content: isMediaSmall ? DrawerContent : SheetContent,
            Footer: isMediaSmall ? DrawerFooter : SheetFooter,
        };
    }, [isMediaSmall]);

    return (
        <>
            <Helmet>
                <title>{Pages.toDo.title()}</title>
            </Helmet>
            <div className="flex flex-col lg:flex-row lg:flex-wrap items-stretch gap-4 px-4">
                {data.map((cc, i, array) => {
                    const isLate = fns.isBefore(cc.from_date, new Date()) && fns.isSameDay(cc.from_date, new Date()) === false;
                    return (
                        <React.Fragment key={cc.id}>
                            {fns.isSameDay(cc.from_date, array[i - 1]?.from_date) === false && (
                                <span className={cn("text-sm font-bold text-gray-600 w-full", isLate ? "text-destructive" : "")}>
                                    {capitalize(fns.format(cc.from_date, 'EEEE d MMMM', { locale: fns_locale.fr }))}
                                </span>
                            )}
                            <CollectConfigCard
                                className="lg:w-[400px]"
                                cc={cc}
                                variant={isLate ? "danger" : "normal"}
                                onClick={() => drawer.focusOn(cc)}
                            />
                        </React.Fragment>
                    );
                })}
                {query.isFetching && (
                    <CollectConfigCardSkeleton className="lg:w-[400px]"/>
                )}
                {query.isFetching === false && query.hasNextPage && (
                    <Button
                        className="w-full"
                        onClick={() => query.fetchNextPage().catch(console.error)}
                    >
                        Charger plus
                    </Button>
                )}
            </div>
            {query.isFetching === false && query.hasNextPage === false && (
                <NoMoreContent />
            )}
            <Container.Base
                open={drawer.isOpen}
                onClose={drawer.close}
                onOpenChange={e => !e ? drawer.close() : void(0)}
            >
                <Container.Content onInteractOutside={drawer.close}>
                    <Container.Header>
                        <h2
                            className={cn("text-xl font-bold", isMediaSmall ? "" : "mb-4")}
                        >
                            Prestation à faire
                        </h2>
                    </Container.Header>
                    <div className="px-4">
                        {drawer.focused && (<CCInfo cc={drawer.focused} />)}
                    </div>
                    <Container.Footer>
                        <Button
                            disabled={!drawer.focused}
                            className='w-full mt-8'
                            onClick={() => {
                                nav(`/prestations/${drawer.focused!.id}/rapport`);
                                drawer.close();
                            }}
                        >
                            Terminer la prestation
                        </Button>
                    </Container.Footer>
                </Container.Content>
            </Container.Base>
        </>
    );
}
