import { Eye, Trash } from "lucide-react";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Dialog, DialogContent } from "./ui/dialog";
import { useFocusDrawer } from "@/hooks/drawers";

export type FileCardProps = {
    file: File;
    onDelete?: (file: File) => void;
};
export const FileCard = (props: FileCardProps) => {
    const dialog = useFocusDrawer<File>();

    return (
        <>
            <Card className="flex items-center gap-2 rounded px-4 py-2">
                <div className="flex-1 flex flex-col py-2 gap-1">
                    <p>
                        {props.file.name}
                    </p>
                </div>
                <div className="grid justify-center items-center">
                    <Button
                        size="icon"
                        variant={'default'}
                        type="button"
                        onClick={() => {
                            dialog.focusOn(props.file);
                        }}
                    >
                        <Eye size="20px" />
                    </Button>
                </div>
                <div className="grid justify-center items-center">
                    <Button
                        size="icon"
                        variant={'destructive'}
                        type="button"
                        onClick={() => {
                            props.onDelete?.(props.file);
                        }}
                    >
                        <Trash size="20px" />
                    </Button>
                </div>
            </Card>
            <Dialog open={dialog.isOpen} onOpenChange={dialog.close}>
                <DialogContent>
                    <div className="p-4 max-w-md">
                        {dialog.focused && (
                            <>
                                <h1 className="text-lg font-bold">{dialog.focused.name}</h1>
                                <img src={URL.createObjectURL(dialog.focused)} alt={dialog.focused.name} />
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
