export const ndlssEndpoints = {
    auth: {
        startOtpSession: () => `/v1/prestas-auth/auth/otp`,
        validateOtpSession: () => `/v1/prestas-auth/auth/otp-verify`,
        closeSession: () => `/v1/prestas-auth/auth/signout`,
        verifyToken: () => `/v1/prestas-auth/auth/verify`,
        getUserFromJWT: () => `/v1/prestas-auth/auth/me`
    },
    collectConfigs: {
        getAll: () => `/v1/collect-configs`,
        retrieve: () => `/v1/collect-configs/retrieve`,
        getById: (id: string) => `/v1/collect-configs/${id}`,
    },
    collects: {
        getAll: () => `/v1/collects`,
        retrieve: () => `/v1/collects/retrieve`,
        getById: (id: string) => `/v1/collects/${id}`,
        createServiceSuccess: () => `/v1/collects/service/success`,
        createServiceHazard: () => `/v1/collects/service/hazard`,
    },
    customers: {
        retrieve: () => `/v1/customers/retrieve`,
        getById: (id: string) => `/v1/customers/${id}`,
    },
    products: {
        getAll: () => `/v1/products`,
        getById: (id: string) => `/v1/products/${id}`,
    },
    documents: {
        createSignedUrl: () => '/v1/documents/create-url',
    },
};
