import * as React from "react"
import { CalendarIcon } from "@radix-ui/react-icons"
import { format } from "date-fns"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { fns_locale } from "@/lib/fns"

export type DateRangePickerProps = {
    from: {
        value: Date | undefined
        set: React.Dispatch<React.SetStateAction<Date | undefined>>
    };
    to: {
        value: Date | undefined
        set: React.Dispatch<React.SetStateAction<Date | undefined>>
    };
};
export function DateRangePicker({
    className,
    from,
    to,
}: React.HTMLAttributes<HTMLDivElement> & DateRangePickerProps) {
    const date = React.useMemo(() => {
        if (!from.value && !to.value) return undefined;

        return {
            from: from.value,
            to: to.value,
        };
    }, [from.value, to.value]);

    const setDate = React.useCallback((date?: { from?: Date; to?: Date }) => {
        if (!date) {
            from.set(undefined);
            to.set(undefined);
            return;
        }
        if (date.from) {
            from.set(date.from);
        }
        if (date.to) {
            to.set(date.to);
        }
    }, [from, to]);

    return (
        <div className={cn("grid gap-2", className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={"outline"}
                        className={cn(
                            "w-[300px] justify-start text-left font-normal",
                            !date && "text-muted-foreground"
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {format(date.from, "dd LLL, y", { locale: fns_locale.fr })} -{" "}
                                    {format(date.to, "dd LLL, y", { locale: fns_locale.fr })}
                                </>
                            ) : (
                                format(date.from, "dd LLL, y", { locale: fns_locale.fr })
                            )
                        ) : (
                            <span>Filtrer par date</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={new Date()}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                        locale={fns_locale.fr}
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
}
