import { Helmet } from 'react-helmet';

import { Pages } from '@/lib/pages';
import { columns } from './table/columns';
import { DataTable } from '@/components/datatable';
import { CC_STATUS, type CollectServiceFront } from '@/types/global';
import { Filters } from './table/filters';
import { useUser } from '@/contexts/user';

import { useCollectHistory } from './data';

export const History = () => {
    const { ro } = useUser();
    const { data, totalCount, ...helper } = useCollectHistory();

    return (
        <>
            <Helmet>
                <title>{Pages.history.title()}</title>
            </Helmet>
            <div className="max-w-7xl flex flex-col h-screen mx-auto mb-10">
                <h1 className="text-2xl font-bold text-center mb-4 mt-2">
                    Historique des prestations
                </h1>
                <DataTable<CollectServiceFront, unknown>
                    data={data ?? []}
                    columns={columns}
                    headDataTable={
                        <Filters
                            status={helper.status}
                            statusOptions={[CC_STATUS.FINISHED, CC_STATUS.HAZARD]}
                            search={helper.search}
                            minDate={helper.minDate}
                            maxDate={helper.maxDate}
                        />
                    }
                    setPagination={helper.pagination.set}
                    pagination={helper.pagination.value}
                    totalCount={totalCount ?? 0}
                    isFetching={helper.isFetching}
                    meta={{ presta: ro }}
                />
            </div>
        </>
    );
}
