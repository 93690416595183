import { format } from "date-fns";
import { AlertTriangle, CalendarIcon, ChevronRight } from "lucide-react";
import React, { useMemo } from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@/components/ui/popover";
import { ProductCard } from "@/components/product-card";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { useDrawer, useFocusDrawer } from "@/hooks/drawers";
import { fns, fns_locale } from "@/lib/fns";
import { cn } from "@/lib/utils";
import {
    CCServiceRo,
    CollectCreateServiceDto,
    ECollectHazardReason,
    ProductInCCOrCO,
} from "@/types/global";
import { EditProductDrawer } from "./edit-product-drawer";
import { AddProductDrawer } from "./add-product-drawer";
import { TimePickerInput } from "@/components/ui/time-picker-input";
import { FileInput } from "@/components/file-input";
import { Card } from "@/components/ui/card";
import { NdlssFile } from "@/hooks/files";
import { FileCard } from "@/components/file-card";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from "@/components/ui/dialog";

import { useReportCcForm } from "./form";
import { outputMapping } from "./mapper";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { humanizeCollectHazardReason } from "@/lib/humanize-map";
import { Textarea } from "@/components/ui/textarea";

export type ReportCCFormProps = {
    cc: CCServiceRo;
    onValid: (data: CollectCreateServiceDto, files: NdlssFile[]) => void;
    isMutating: boolean;
};

export const ReportCCForm: React.FC<ReportCCFormProps> = (props) => {
    const now = fns
        .toDate(props.cc.from_date)
        .setHours(fns.getHours(new Date()), fns.getMinutes(new Date()), 0, 0);

    const { form, submit } = useReportCcForm({
        initialValues: {
            is_finished: undefined,
            cart: props.cc.products,
            scheduled_at: fns.toDate(props.cc.from_date),
            files: [],
            started_at: fns.toDate(now),
            ended_at: fns.addMinutes(now, 15),
        },
        onValid: async (data) => {
            const { dto, files } = await outputMapping({
                values: data,
                cc: props.cc,
            });
            props.onValid(dto, files);
        },
        onInvalid: (errors) => {
            console.error(errors);
        },
    });
    const productEditDrawer = useFocusDrawer<ProductInCCOrCO>();
    const comfirmDrawer = useDrawer();
    const submitRef = React.useRef<HTMLButtonElement>(null);
    const { isDirty } = form.formState;

    const displayMain = useMemo(() => {
        return isDirty;
    }, [isDirty]);

    const isFinished = form.watch("is_finished");

    return (
        <Form {...form}>
            <form className="flex flex-col gap-4 mt-2" onSubmit={submit}>
                <h2 className="text-lg font-bold">Prestation</h2>
                <FormField
                    control={form.control}
                    name="is_finished"
                    render={({ field }) => (
                        <div className="flex gap-4 items-center my-4 mx-4 flex-wrap mx-auto">
                            <Button
                                type="button"
                                className="flex-1"
                                variant={field.value === true ? "default" : "outline"}
                                onClick={() => {
                                    field.onChange(true);
                                    form.clearErrors();
                                }}
                            >
                                Prestation effectuée
                            </Button>
                            <Button
                                type="button"
                                className="flex-1"
                                variant={field.value === false ? "default" : "outline"}
                                onClick={() => {
                                    field.onChange(false);
                                    form.clearErrors();
                                }}
                            >
                                Échec de la prestation
                            </Button>
                        </div>
                    )}
                />
                {
                    displayMain &&
                    <>
                    {
                        !isFinished &&
                        <>
                        <FormField
                            control={form.control}
                            name="hazard_reason"
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-auto max-w-xl">
                                    <FormLabel>Motif d'échec</FormLabel>
                                    <FormControl>
                                        <Select
                                            value={field.value}
                                            onValueChange={(value: ECollectHazardReason) => field.onChange(value)}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Sélectionnez un motif"/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        Object.values(ECollectHazardReason).map((reason) => (
                                                            <SelectItem key={reason} value={reason}>
                                                                {humanizeCollectHazardReason(reason)}
                                                            </SelectItem>
                                                        ))
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="hazard_comment"
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-auto max-w-xl">
                                    <FormLabel>Commentaire</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            placeholder="Détails supplémentaires sur l'échec..."
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        </>
                    }
                    <FormField
                        control={form.control}
                        name="scheduled_at"
                        render={({ field }) => (
                            <FormItem className="flex flex-col">
                                <FormLabel>{isFinished ? "Effectuée le" : "Échouée le"}</FormLabel>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FormControl>
                                            <Button
                                                variant={"outline"}
                                                className={cn(
                                                    "w-[240px] pl-3 text-left font-normal",
                                                    !field.value && "text-muted-foreground"
                                                )}
                                            >
                                                {format(field.value, "PPP", { locale: fns_locale.fr })}
                                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                            </Button>
                                        </FormControl>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="w-auto p-0 border-2 border-slate-100 rounded-md"
                                        align="start"
                                    >
                                        <Calendar
                                            mode="single"
                                            selected={field.value}
                                            onSelect={field.onChange}
                                            locale={fns_locale.fr}
                                        />
                                    </PopoverContent>
                                </Popover>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="flex items-center gap-4">
                        <small>Entre</small>
                            <FormField
                                control={form.control}
                                name="started_at"
                                render={({ field }) => (
                                    <FormItem>
                                        <Card className="flex items-center">
                                        <TimePickerInput
                                            className="text-sm w-10 h-8"
                                            picker="hours"
                                            date={field.value}
                                            setDate={field.onChange}
                                        />
                                        <p className="font-bold">:</p>
                                        <TimePickerInput
                                            className="text-sm w-10 h-8"
                                            picker="minutes"
                                            date={field.value}
                                            setDate={field.onChange}
                                        />
                                        </Card>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        <small>et</small>
                            <FormField
                                control={form.control}
                                name="ended_at"
                                render={({ field }) => (
                                    <div className="flex items-center gap-2 flex-wrap">
                                        <FormItem>
                                            <Card className="flex items-center">
                                                <TimePickerInput
                                                    className="text-sm w-10 h-8"
                                                    picker="hours"
                                                    date={field.value}
                                                    setDate={field.onChange}
                                                />
                                                <p className="font-bold">:</p>
                                                <TimePickerInput
                                                    className="text-sm w-10 h-8"
                                                    picker="minutes"
                                                    date={field.value}
                                                    setDate={field.onChange}
                                                />
                                            </Card>
                                        </FormItem>
                                        <FormMessage/>
                                    </div>
                                )}
                            />
                    </div>
                    {
                        isFinished &&
                        <FormField
                            control={form.control}
                            name="cart"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Contenu de la prestation</FormLabel>
                                    <div className="flex flex-col gap-4">
                                        {field.value?.map((cartItem, index) => (
                                            <ProductCard
                                                key={index + cartItem.id}
                                                productId={cartItem.id}
                                                quantity={cartItem.quantity}
                                                product={cartItem as ProductInCCOrCO}
                                                onClick={() => {
                                                    productEditDrawer.focusOn(cartItem as ProductInCCOrCO);
                                                }}
                                            />
                                        ))}
                                        <AddProductDrawer
                                            zone={props.cc.zone}
                                            onAdd={(prd) => {
                                                const exists = field.value.find(
                                                    (product) => product.id === prd.id
                                                );

                                                if (exists) {
                                                    const newCart = field.value
                                                        .map((product) => {
                                                            if (product.id === prd.id) {
                                                                return {
                                                                    ...product,
                                                                    quantity: product.quantity + 1,
                                                                };
                                                            }
                                                            return product;
                                                        })
                                                        .filter((product) => product.quantity > 0);
                                                    field.onChange(newCart);
                                                    return;
                                                }
                                                field.onChange([...field.value, prd]);
                                            }}
                                        />
                                    </div>
                                    <EditProductDrawer
                                        drawer={productEditDrawer}
                                        onSubmit={(prd) => {
                                            const newCart = field.value
                                                .map((product) => {
                                                    if (product.id === prd.id) {
                                                        return prd;
                                                    }
                                                    return product;
                                                })
                                                .filter((product) => product.quantity > 0);
                                            field.onChange(newCart);
                                        }}
                                    />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    }
                    <FormField
                        control={form.control}
                        name="files"
                        render={({ field }) => (
                            <FormItem>
                                <div>
                                    <FormLabel>Photos</FormLabel>
                                </div>
                                <FileInput
                                    label="Ajouter une photo"
                                    onFileEntry={(file) => {
                                        field.onChange([...field.value, file]);
                                    }}
                                />
                                <div className="flex flex-col gap-2">
                                    {field.value.map((file, index) => (
                                        <FileCard
                                            key={index + file.name}
                                            file={file}
                                            onDelete={(file) => {
                                                field.onChange(
                                                    field.value.filter((f) => f.name !== file.name)
                                                );
                                            }}
                                        />
                                    ))}
                                </div>
                            </FormItem>
                        )}
                    />
                    </>
                }
                <div>
                    <div className="flex justify-end">
                        <Button
                            type="button"
                            onClick={comfirmDrawer.open}
                            disabled={(form.formState.isSubmitted && !form.formState.isValid) || isFinished === undefined}
                        >
                            <div>Soumettre le rapport</div>
                            <div>
                                <ChevronRight size={"20px"} />
                            </div>
                        </Button>
                    </div>
                </div>
                <button ref={submitRef} type="submit" className="w-0 f-0 hidden" />
                <Dialog
                    open={comfirmDrawer.isOpen}
                    onOpenChange={(e) => (e ? void 0 : comfirmDrawer.close())}
                >
                    <DialogContent
                        className="rounded [&>button]:hidden"
                        autoFocus={false}
                    >
                        <DialogHeader>
                            <h2 className="text-bold text-xl">Soumettre le rapport</h2>
                        </DialogHeader>
                        <div className="flex flex-col gap-4">
                            <p>
                                Vous allez soumettre un rapport de prestation avec les
                                informations suivantes:
                            </p>
                            <div className="flex flex-col gap-2">
                                <p>
                                    <span className="font-bold">Date: </span>
                                    <span>
                                        {format(form.getValues("scheduled_at"), "PPP", {
                                            locale: fns_locale.fr,
                                        })}
                                    </span>
                                </p>
                                <p>
                                    <span className="font-bold">Heure de début: </span>
                                    <span>{format(form.getValues("started_at"), "HH:mm")}</span>
                                </p>
                                <p>
                                    <span className="font-bold">Heure de fin: </span>
                                    <span>{format(form.getValues("ended_at"), "HH:mm")}</span>
                                </p>
                                <p>
                                    <div className="font-bold">Contenu de la prestation: </div>
                                    {form.getValues("cart").map((prd) => (
                                        <div className="pl-4" key={prd.id}>
                                            {prd.quantity} x {prd.name}
                                        </div>
                                    ))}
                                </p>
                            </div>
                            <p>
                                Êtes-vous sûr de vouloir soumettre ce rapport ?
                                <br />
                                <i className="flex items-center gap-1 text-destructive opacity-80">
                                    <AlertTriangle size={"1rem"} /> Cette action est irréversible.
                                </i>
                            </p>
                        </div>
                        <DialogFooter className="">
                            <Button
                                onClick={() => {
                                    comfirmDrawer.close();
                                }}
                            >
                                <div>Annuler</div>
                            </Button>
                            <Button
                                variant={"outline"}
                                onClick={() => {
                                    comfirmDrawer.close();
                                    submitRef.current?.click();
                                }}
                            >
                                <div>Confirmer</div>
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </form>
        </Form>
    );
};
