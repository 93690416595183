import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { httpClient } from "@/lib/axios";
import { ndlssEndpoints } from "@/lib/ndlssEndpoints";
import { queryKey } from "@/lib/query";
import { CCServiceRo, CollectCreateServiceDto, IBbngResponse } from "@/types/global";
import { AxiosResponse } from "axios";

export type UseCollectCreateProps = {
    options?: Partial<
        UseMutationOptions<IBbngResponse<CCServiceRo>, Error, CollectCreateServiceDto, unknown>
    >
};

export const useCollectCreate = (props: UseCollectCreateProps = {}) => {
    const query = useMutation({
        ...props.options,
        mutationKey: [queryKey.collect] as const,
        mutationFn: async (dto: CollectCreateServiceDto) => {
            let response: AxiosResponse<IBbngResponse<CCServiceRo>>;
            if (dto.status === 'FINISHED') {
                response = await httpClient.post<IBbngResponse<CCServiceRo>>(ndlssEndpoints.collects.createServiceSuccess(), dto);
            } else {
                response = await httpClient.post<IBbngResponse<CCServiceRo>>(ndlssEndpoints.collects.createServiceHazard(), dto);
            }

            return response.data;
        },
    });

    return query;
};
