import React from "react";

export type DrawerProps = {
    isOpen?: boolean;
};

export const useDrawer = (props: DrawerProps = {}) => {
    const [isOpen, setIsOpen] = React.useState(props.isOpen ?? false);
    const open = React.useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);
    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return {
        isOpen,
        open,
        close,
    };
};

export type FocusDrawerProps<FocusType = any> = {
    defaultFocus?: FocusType;
    isOpen?: boolean;
};

export const useFocusDrawer = <FocusType = any>(props: FocusDrawerProps = {}) => {
    const [focused, setFocused] = React.useState<FocusType | undefined>(props.defaultFocus);
    const [isOpen, setIsOpen] = React.useState(props.isOpen ?? false);
    const focusOn = React.useCallback((cc: FocusType) => {
        setFocused(cc);
        setIsOpen(true);
    }, [setFocused, setIsOpen]);
    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return {
        focused,
        focusOn,
        isOpen,
        close,
    };
};

