import React from "react";
import { useMediaQuery } from "usehooks-ts";
import { Helmet } from "react-helmet";

import { CollectConfigCard, CollectConfigCardSkeleton } from "@/components/collect-config-card";
import { fns, fns_locale } from "@/lib/fns";
import { capitalize } from "@/lib/humanize-map";
import { Button } from "@/components/ui/button";
import { NoMoreContent } from "@/components/no-more-content";
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from "@/components/ui/drawer";
import { Sheet, SheetContent, SheetHeader, SheetFooter } from "@/components/ui/sheet";
import { CCInfo } from "@/components/cc-info";
import { cn } from "@/lib/utils";
import { Pages } from "@/lib/pages";

import { useToCome } from "./data";

export const ToCome = () => {
    const { data, query, drawer } = useToCome();
    const isMediaSmall = useMediaQuery('(max-width: 1280px)');

    const Container = React.useMemo(() => {
        return {
            Base: isMediaSmall ? Drawer : Sheet,
            Header: isMediaSmall ? DrawerHeader : SheetHeader,
            Content: isMediaSmall ? DrawerContent : SheetContent,
            Footer: isMediaSmall ? DrawerFooter : SheetFooter,
        };
    }, [isMediaSmall]);

    return (
        <>
            <Helmet>
                <title>{Pages.toCome.title()}</title>
            </Helmet>
            <div className="flex flex-col lg:flex-row lg:flex-wrap items-stretch gap-4 px-4">
                {data.map((cc, i, array) => {
                    return (
                        <React.Fragment key={cc.id}>
                            {fns.isSameDay(cc.from_date, array[i - 1]?.from_date) === false && (
                                <span className="text-sm font-bold text-gray-600 w-full">
                                    {capitalize(fns.format(cc.from_date, 'EEEE d MMMM', { locale: fns_locale.fr }))}
                                </span>
                            )}
                            <CollectConfigCard className="lg:w-[400px] cursor-pointer" cc={cc} onClick={() => drawer.focusOn(cc)}/>
                        </React.Fragment>
                    );
                })}
                {query.isFetching && (
                    <CollectConfigCardSkeleton className="lg:w-[400px]"/>
                )}
                {query.isFetching === false && query.hasNextPage && (
                    <Button
                        className="w-full"
                        onClick={() => query.fetchNextPage().catch(console.error)}
                    >
                        Charger plus
                    </Button>
                )}
            </div>
            {query.isFetching === false && query.hasNextPage === false && (
                <NoMoreContent />
            )}
            <Container.Base
                open={drawer.isOpen}
                onClose={drawer.close}
                onOpenChange={e => !e ? drawer.close() : void(0)}
            >
                <Container.Content onInteractOutside={drawer.close}>
                    <Container.Header>
                        <h2
                            className={cn("text-xl font-bold", isMediaSmall ? "" : "mb-4")}
                        >
                            Prestation à venir
                        </h2>
                    </Container.Header>
                    <div className="px-4">
                        {drawer.focusedCC && (<CCInfo cc={drawer.focusedCC} />)}
                    </div>
                    <Container.Footer />
                </Container.Content>
            </Container.Base>
        </>
    );
}
