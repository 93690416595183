import React from "react";
import { CalendarCheck, Upload, Download, CalendarClock, MessageSquare, RefreshCw } from 'lucide-react';

import { CCServiceRo, CC_FAMILY, PRODUCT_FAMILY } from "@/types/global";

import { Card } from "@/components/ui/card";
import { fns } from "@/lib/fns";
import { cn } from "@/lib/utils";
import { Badge } from "./ui/badge";
import { CCBadgeCategory } from "./cc-badge-category";

type CollectConfigCardProps = {
    cc: CCServiceRo;
    className?: string;
    onClick?: () => void;
    variant?: 'normal' | 'danger';
};

export const CollectConfigCard: React.FC<CollectConfigCardProps> = ({ cc, className, onClick, variant = 'normal' }) => {
    const info: React.ReactNode = React.useMemo(() => {
        switch (cc.family) {
            case CC_FAMILY.COLLECT_DUMPSTER_ROTATION: {
                const retrievalProduct = cc.products.find(p => p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL);
                const depositProduct = cc.products.find(p => p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT);

                return (
                    <div>
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-1">
                                <Download size="0.8rem" />
                                <small>{depositProduct?.volume_m3}m³</small>
                                <small> - </small>
                                <small>{depositProduct?.name}</small>
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-1">
                                <Upload size="0.8rem" />
                                <small>{retrievalProduct?.volume_m3}m³</small>
                                <small> - </small>
                                <small>{retrievalProduct?.name}</small>
                            </div>
                        </div>
                    </div>
                );
            }
            case CC_FAMILY.DELIVERY_BIG_BAG: {
                const bags = cc.products.reduce((acc, p) => acc + p.quantity, 0);

                return <small>{bags} sacs</small>;
            }
            case CC_FAMILY.COLLECT_BIG_BAG: {
                const bags = cc.products.reduce((acc, p) => acc + p.quantity, 0);
                const volume = cc.products.reduce((acc, p) => acc + p.volume_m3 * p.quantity, 0);

                return (
                    <div className="flex items-center gap-1">
                        <Upload size="0.8rem"/>
                        <small>
                            {volume}m³ ({bags} sacs)
                        </small>
                    </div>
                );
            }
            case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL: {
                const product = cc.products[0];

                return (
                    <div>
                        <div className="flex items-center gap-1">
                            {cc.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT && <Download size="0.8rem" />}
                            {cc.family === CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL && <Upload size="0.8rem" />}
                            {cc.family === CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT && <RefreshCw size="0.8rem" />}
                            <small>{product.volume_m3}m³</small>
                            <small> - </small>
                            <small>{product.name}</small>
                        </div>
                    </div>
                )
            }
            case CC_FAMILY.ADMINISTRATIVE:
            default:
                return '';
        }
    }, [cc]);

    return (
        <Card
            className={
                cn(
                    "flex items-stretch gap-4 px-2 py-4 overflow-hidden",
                    variant === 'danger' ? 'border-destructive' : '',
                    onClick ? 'cursor-pointer' : '',
                    className
                )
            }
            onClick={onClick}
        >
            {variant === 'danger' && (<CalendarClock size="24px" className={"text-destructive"} />)}
            {variant === 'normal' && (<CalendarCheck size="24px" />)}
            <div className="flex flex-col flex-1">
                <div className="flex items-center gap-2 mb-2">
                    <CCBadgeCategory family={cc.family} />
                    <Badge variant={"secondary"}>
                        {cc?.order_number}
                    </Badge>
                    {cc.comment && (
                        <div className="flex-1 flex flex-row-reverse">
                            <MessageSquare size="1.25rem" />
                        </div>
                    )}
                </div>
                <div>
                    <small>{fns.format(cc.from_date, 'dd/MM/yyyy')}</small>
                    {' '}
                    <small>{fns.format(cc.from_date, 'hh:mm')} - {fns.format(cc.to_date, 'hh:mm')}</small>
                </div>
                <small className="font-bold">{cc.address.formatted_name}</small>
                <div className="flex gap-3 items-center">
                    {info}
                </div>
            </div>
        </Card>
    );
}

type CollectConfigCardSkeletonProps = {
    className?: string;
};
export const CollectConfigCardSkeleton: React.FC<CollectConfigCardSkeletonProps> = (props) => {
    return (
        <Card className={cn("flex items-center gap-4 px-2 py-4 overflow-hidden animate-pulse", props.className)}>
            <CalendarCheck size="24px" />
            <div className="flex flex-col gap-1 flex-1">
                <div className="w-3/5 h-4 bg-gray-200 rounded-full"></div>
                <div className="w-4/5 h-4 bg-gray-200 rounded-full"></div>
                <div className="w-3/5 h-4 bg-gray-200 rounded-full"></div>
                <div className="w-2/5 h-4 bg-gray-200 rounded-full"></div>
            </div>
        </Card>
    );
};
