import { useInfiniteQuery } from "@tanstack/react-query";

import { httpClient } from "@/lib/axios";
import { ndlssEndpoints } from "@/lib/ndlssEndpoints";
import { queryKey } from "@/lib/query";
import { CCServiceRo, CollectConfigQuery, IBbngResponse } from "@/types/global";

export type UseCollectConfigsListingProps = {
    queryParams: CollectConfigQuery;
};

export const useCollectConfigsListing = (props: UseCollectConfigsListingProps) => {
    const infiniteQuery = useInfiniteQuery({
        queryKey: [queryKey.cc.infinite, props.queryParams] as const,
        queryFn: async (ctx) => {
            const params = ctx.queryKey[1];

            const response = await httpClient.get<IBbngResponse<CCServiceRo[]>>(ndlssEndpoints.collectConfigs.getAll(), {
                params: <CollectConfigQuery>{
                    ...params,
                    limit: 25,
                    page: ctx.pageParam,
                    orderByCollectDateAsc: true
                },
                signal: ctx.signal,
            });

            return response.data;
        },
        getNextPageParam: (lastPage) => {
            const metadata = lastPage.metadata;
            const pages = metadata.pages_count;
            const currentPage = metadata.page;

            const nextPage = currentPage < pages ? currentPage + 1 : undefined;

            return nextPage;
        },
        getPreviousPageParam: (page) => {
            const metadata = page.metadata;
            const currentPage = metadata.page;

            const prevPage = currentPage > 1 ? currentPage - 1 : undefined;

            return prevPage;
        },
        maxPages: 5,
        initialPageParam: 1,
    });

    return infiniteQuery;
};
