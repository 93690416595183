import { CC_FAMILY, CC_STATUS, ECollectHazardReason, EPlanningType, ETrashType, PRODUCT_FAMILY } from "@/types/global";

export const capitalize = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const humanizeCcFamily = (value: CC_FAMILY): string => {
    switch (value) {
        case CC_FAMILY.ADMINISTRATIVE:
            return 'Administrative';
        case CC_FAMILY.COLLECT_BIG_BAG:
            return 'Collecte de big bag';
        case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            return 'Dépôt de benne';
        case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            return 'Attente de chargement';
        case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            return 'Récupération de benne';
        case CC_FAMILY.COLLECT_DUMPSTER_ROTATION:
            return 'Rotation de benne';
        case CC_FAMILY.DELIVERY_BIG_BAG:
            return 'Livraison de big bag';
        default:
            return value;
    }
}

export const humanizePlanningType = (value: EPlanningType): string => {
    switch (value) {
        case EPlanningType.BIG_BAG:
            return 'Big bag';
        case EPlanningType.DUMPSTER:
            return 'Benne';
        default:
            return value;
    }
};

export const humanizeProductFamily = (value: PRODUCT_FAMILY): string => {
    switch (value) {
        case PRODUCT_FAMILY.COLLECT_BIG_BAG:
            return 'Collecte de big bag';
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            return 'Dépôt de benne';
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            return 'Attente de chargement';
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            return 'Récupération de benne';
        case PRODUCT_FAMILY.DELIVERY_BIG_BAG:
            return 'Livraison de big bag';
        default:
            return value;
    }
};

export const humanizeTrashType = (value: ETrashType): string => {
    switch (value) {
        case ETrashType.ASBESTOS:
            return 'Amiante';
        case ETrashType.GLASS:
            return 'Verre';
        case ETrashType.LEAD:
            return 'Plomb';
        case ETrashType.METAL:
            return 'Métal';
        case ETrashType.MINERAL_FRACTION:
            return 'Fraction minérale';
        case ETrashType.MIXED:
            return 'Mélangé';
        case ETrashType.PAPER_CARDBOARD:
            return 'Papier/carton';
        case ETrashType.PLASTER:
            return 'Plâtre';
        case ETrashType.PLASTIC:
            return 'Plastique';
        case ETrashType.RUBBLE:
            return 'Gravat';
        case ETrashType.WOOD:
            return 'Bois';
        case ETrashType.OIW:
            return 'DIB';
        case ETrashType.GREEN_TRASH:
            return 'Vert';
        case ETrashType.ULTIMATE_TRASH:
            return 'Ultime';
        default:
            return 'Type inconnu';
    }
}

export const humanizeCcStatus = (value?: CC_STATUS): string => {
    switch (value) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
            return 'A vérifier';
        case CC_STATUS.TO_PREPARE:
            return 'A livrer';
        case CC_STATUS.TO_PLAN:
            return 'A planifier';
        case CC_STATUS.PLANNED:
            return 'Planifiée';
        case CC_STATUS.CANCELED:
            return 'Annulée';
        case CC_STATUS.FINISHED:
            return 'Terminée';
        case CC_STATUS.HAZARD:
            return 'Aléa';
        case CC_STATUS.ORDER_TO_PAY:
            return 'Commande à payer';
        case CC_STATUS.SPLITTED:
            return 'Scindée';
        default:
            return 'Statut inconnu';
    }

};

export const humanizeFileSize = (size: number): string => {
    const units = ['o', 'Ko', 'Mo', 'Go', 'To'];
    let i = 0;
    while (size > 1000) {
        size /= 1000;
        i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
};

export const humanizeCollectHazardReason = (value?: ECollectHazardReason | null): string => {
    switch (value) {
        case ECollectHazardReason.BAD_FILLING:
            return 'Mauvais remplissage';
        case ECollectHazardReason.DAMAGED:
            return 'Produit abimé';
        case ECollectHazardReason.NO_BAG:
            return 'Produit introuvable/inaccessible';
        case ECollectHazardReason.ORDER_ERROR:
            return 'Erreur à la commande';
        case ECollectHazardReason.OBSTRUCTIVE_PARKING:
            return 'Parking génant';
        case ECollectHazardReason.NO_ACCESS:
            return 'Accès impossible';
        case ECollectHazardReason.NO_SPACE:
            return "Pas d'espace suffisant";
        case ECollectHazardReason.NOBODY_ON_SITE:
            return 'Aucun interlocuteur sur place';
        case ECollectHazardReason.OVERLOADED_DUMPSTER:
            return 'Benne surchargée';
        case ECollectHazardReason.OTHER:
            return 'Autre';
        default:
            return 'Aléa inconnu';
    }
};
