import { Image } from "lucide-react";

import { useFocusDrawer } from "@/hooks/drawers";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { humanizeFileSize } from "@/lib/humanize-map";

export type FileInputProps = {
    className?: string;
    onFileEntry?: (file: File) => void;
    accept?: string;
    label: string;
};

export const FileInput = (props: FileInputProps) => {
    const drawer = useFocusDrawer<File>();

    return (
        <>
            <div>
            <input type="file" id="files" className="hidden"
                    value={''}
                    accept={props.accept ?? 'image/*'}
                    onChange={ev => {
                        if (ev.target.files?.[0]) {
                            drawer.focusOn(ev.target.files[0]);
                        }
                    }}

                />
                <label htmlFor="files">
                    <div
                        role="button"
                        className={cn(
                            "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors",
                            "border border-input bg-background shadow-sm",
                            "hover:bg-accent hover:text-accent-foreground hover:cursor-pointer",
                            "h-9 px-4 py-2"
                        )}
                    >
                        <div>
                            <Image size="20px" className="mr-2"/>
                        </div>
                        <div>
                            {props.label}
                        </div>
                    </div>
                </label>
            </div>
            <Dialog open={drawer.isOpen} onOpenChange={e => !e && drawer.close()}>
                <DialogContent>
                    <DialogHeader>
                        <h2 className="text-lg font-bold">{props.label}</h2>
                    </DialogHeader>
                    <div className="px-4">
                        {drawer.focused && (
                            <div className="flex flex-col gap-2">
                                <div className="max-h-[500px] max-w-[400px] flex justify-center">
                                    <img src={URL.createObjectURL(drawer.focused)} alt={drawer.focused.name} className="max-h-[500px]" />
                                </div>
                                <p>
                                    Vous avez sélectionné le fichier suivant: <br /><i>{drawer.focused.name}</i>
                                </p>
                                <p>
                                    Taille : {humanizeFileSize(drawer.focused.size)}
                                </p>
                                <p>
                                    <b>
                                        Êtes-vous sûr de vouloir utiliser ce fichier ?
                                    </b>
                                </p>
                            </div>
                        )}
                    </div>
                    <DialogFooter>
                        <div className="w-full flex justify-evenly gap-4">
                            <Button
                                className="flex-1"
                                variant={"destructive"}
                                onClick={() => {
                                    drawer.close();
                                }}
                            >
                                Annuler
                            </Button>
                            <Button
                                className="flex-1"
                                onClick={() => {
                                    if (drawer.focused) {
                                        props.onFileEntry?.(drawer.focused);
                                    }
                                    drawer.close();
                                }}
                            >
                                Valider
                            </Button>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};
