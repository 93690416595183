import React from "react";
import type { SetStateAction } from "react";
import { X } from "lucide-react";
import { useDebounceCallback } from "usehooks-ts";

import type { CC_STATUS } from "@/types/global";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DateRangePicker } from "@/components/date-range-picker";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { humanizeCcStatus } from "@/lib/humanize-map";

export type FiltersProps = {
    status: {
        value: CC_STATUS | '';
        set: React.Dispatch<React.SetStateAction<CC_STATUS | ''>>;
    };
    search: {
        value?: string;
        set: React.Dispatch<SetStateAction<string | undefined>>;
    };
    minDate: {
        value: Date | undefined;
        set: React.Dispatch<React.SetStateAction<Date | undefined>>;
    };
    maxDate: {
        value: Date | undefined;
        set: React.Dispatch<React.SetStateAction<Date | undefined>>;
    };
    statusOptions: CC_STATUS[];
}
export const Filters: React.FC<FiltersProps> = (props) => {
    const debounceSearch = useDebounceCallback(props.search.set, 500);
    const searchRef = React.useRef<HTMLInputElement>(null);

    const isFiltering = React.useMemo(() => {
        if (props.minDate.value !== undefined) return true;
        if (props.maxDate.value !== undefined) return true;
        if (props.search.value !== undefined && props.search.value.length >= 3) return true;
        if (props.status.value !== '') return true;
    }, [props.maxDate, props.minDate, props.search, props.status]);

    return (
        <div className="flex justify-start items-end flex-1 gap-4">
            <div className="min-w-52">
                <Input
                    ref={searchRef}
                    defaultValue={props.search.value ?? ''}
                    onChange={e => debounceSearch(e.target.value)}
                    placeholder="Filtrer par n° de commande"
                />
            </div>
            <div className="w-40">
                <Select value={props.status.value} onValueChange={value => props.status.set(value as CC_STATUS | '')}>
                    <SelectTrigger>
                        <SelectValue placeholder="Filtrer par status" />
                    </SelectTrigger>
                    <SelectContent>
                        {props.statusOptions.map(e => (
                            <SelectItem key={e} value={e} onClick={() => props.status.set(e)}>
                                {humanizeCcStatus(e)}
                            </SelectItem>

                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div>
                <DateRangePicker
                    from={props.minDate}
                    to={props.maxDate}
                />
            </div>
            {isFiltering && (
                <div>
                    <Button
                        variant={"secondary"}
                        onClick={() => {
                            props.minDate.set(undefined);
                            props.maxDate.set(undefined);
                            props.search.set('');
                            props.status.set('');
                            searchRef.current && (searchRef.current.value = '');
                        }}
                    >
                        <X className="h-4 w-4 mr-2" />
                        Réinitialiser
                    </Button>
                </div>
            )}
        </div>
    );
};
