import EndlessTypo from "@/assets/endless_typo.png";
import { Dialog, DialogContent } from "./ui/dialog";
import { Spinner } from "./spinner";

export type LoaderDialogProps = {
    show?: boolean;
    info?: string | (() => React.ReactNode);
    enableMask?: boolean;
};
export const LoaderDialog: React.FC<LoaderDialogProps> = (props) => {
    return (
        <Dialog open={props.show} modal={props.enableMask ?? false}>
            <DialogContent className="rounded [&>button]:hidden" autoFocus={false}>
                <div className="flex flex-col justify-center items-center gap-6 p-4">
                    <img src={EndlessTypo} alt="Endless" className="w-[300px] max-w-full" />
                    <Spinner className="max-w-16" />
                    {
                        typeof props.info === "function"
                            ? props.info()
                            : (
                                <p className="text-center text-gray-600 text-lg">
                                    {props.info ?? "Chargement..."}
                                </p>
                            )
                    }
                </div>
            </DialogContent>
        </Dialog>
    );
};
