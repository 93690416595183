import { Button } from "@/components/ui/button";
import { Pages } from "@/lib/pages";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Listings: React.FC = () => {
    const { pathname } = useLocation();
    const nav = useNavigate();

    const activePage = React.useMemo(() => {
        if (pathname === Pages.toDo.path()) {
            return "to-do";
        } else if (pathname === Pages.toCome.path()) {
            return "to-come";
        } else {
            return "to-validate";
        }
    }, [pathname]);

    return (
        <div className="max-w-7xl mx-auto">
            <div className="flex gap-4 justify-between items-center my-4 mx-4">
                <Button
                    className="flex-1"
                    variant={activePage === 'to-do' ? "default" : "outline"}
                    onClick={() => nav(Pages.toDo.path())}
                >
                    À Faire
                </Button>
                <Button
                    className="flex-1"
                    variant={activePage === 'to-come' ? "default" : "outline"}
                    onClick={() => nav(Pages.toCome.path())}
                >
                    À Venir
                </Button>
            </div>
            <Outlet />
        </div>
    );
};
