import React, { PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';

import { IBbngResponse, PrestaRo } from '@/types/global';
import { ndlssEndpoints } from '@/lib/ndlssEndpoints';
import { httpClient } from '@/lib/axios';

import { useAuth } from './auth';
import { queryKey } from '@/lib/query';
import { Sentry } from '@/lib/sentry';

type UserContextStates = {
    ro: PrestaRo | undefined;
    isFetching: boolean;
    isError: boolean;
};

type UserContextMethods = {
    // Empty
};

export type UserContextType = UserContextStates & UserContextMethods;

const defaultUserContext: UserContextType = {
    ro: undefined,
    isFetching: false,
    isError: false,
};

const UserContext = React.createContext<UserContextType>(defaultUserContext);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { hydrated, isAuthenticated } = useAuth();
    const userQuery = useQuery({
        queryKey: [queryKey.user.me],
        queryFn: async () => {
            const res = await httpClient.get<IBbngResponse<PrestaRo>>(ndlssEndpoints.auth.getUserFromJWT());
            return res.data.data?.ro;
        },
        enabled: hydrated && isAuthenticated,
        gcTime: 15 * 60 * 1000,
    });

    React.useEffect(() => {
        if (userQuery.data) {
            Sentry.setUser({
                email: userQuery.data.email,
                id: userQuery.data.id,
                username: userQuery.data.name,
            });
        }
    }, [userQuery.data]);

    const value = React.useMemo<UserContextType>(() => ({
        ro: userQuery.data,
        isError: userQuery.isError,
        isFetching: userQuery.isFetching,
    }), [userQuery.data, userQuery.isFetching, userQuery.isError]);
    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => React.useContext(UserContext);
