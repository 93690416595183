import { ColumnDef } from '@tanstack/react-table';
import { MessageCircle, MoreHorizontal } from 'lucide-react';
import Dinero from 'dinero.js';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { CC_FAMILY, CC_STATUS, PRODUCT_FAMILY, PlanningShiftStepCategory, type CCServiceRo, type CollectServiceFront, type PrestaPrice, type ProductInCCOrCO } from '@/types/global';
import { humanizeCcStatus, humanizeCollectHazardReason } from '@/lib/humanize-map';
import { fns, fns_locale } from '@/lib/fns';
import { arrayToMap } from '@/lib/common';
import { cn } from '@/lib/utils';

const displayVolumeFromProducts = (products: ProductInCCOrCO[], family: CC_FAMILY): string => {
    if (family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION) {
        const volDeposit = products.find((p) => p.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT)?.volume_m3 || 0;
        const volRetrieval = products.find((p) => p.family === CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL)?.volume_m3 || 0;
        return `DEP ${volDeposit}m³ / ENL ${volRetrieval}m³`;
    }
    const volume = products.reduce((acc, curr) => {
        return acc + (curr?.volume_m3 || 0) * curr.quantity;
    }, 0);
    return `${volume}m³`;
};

export const MissingInformation = () => <div>Information manquante</div>;

export const columns: ColumnDef<CollectServiceFront>[] = [
    {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => {
            const co = row.original;

            return (
                <TooltipProvider>
                    <Tooltip delayDuration={200}>
                        {co.status === CC_STATUS.HAZARD ? (
                            <TooltipTrigger>
                                <Badge
                                    className={cn(
                                        co.status === "HAZARD" && "bg-red-400 hover:bg-red-500",
                                    )}
                                >
                                    {humanizeCcStatus(co.status)}
                                </Badge>
                            </TooltipTrigger>
                        ) : (
                            <Badge
                                className={cn(
                                    co.status === "CANCELED" && "bg-orange-400 hover:bg-orange-500",
                                    co.status === "FINISHED" && "bg-green-400 hover:bg-green-500",
                                )}
                            >
                                {humanizeCcStatus(co.status)}
                            </Badge>
                        )}
                        <TooltipContent className='py-2'>
                            <div className='flex flex-col gap-4 max-w-lg break-words'>
                                {humanizeCollectHazardReason(co.hazard_reason)}
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        },
    },
    {
        header: 'Numéro de commande',
        accessorKey: 'order_number',
        cell: ({ row }) => {
            const co = row.original;
            if (typeof co.informations.collect_config_id === 'string') {
                return <MissingInformation />;
            }
            const cc = co.informations.collect_config_id;
            if (cc.family === CC_FAMILY.ADMINISTRATIVE) return <MissingInformation />;

            return <div>{cc.order_number} / {cc.number}</div>
        },
    },
    {
        header: 'Adresse',
        accessorKey: 'address',
        cell: ({ row }) => {
            const co = row.original;
            if (typeof co.informations.collect_config_id === 'string') return <MissingInformation />;
            const cc = co.informations.collect_config_id;

            return <div>{cc.address.formatted_name}</div>
        },
    },
    {
        header: 'Date de collecte',
        accessorKey: 'completed_at',
        cell: ({ row }) => {
            const co = row.original;

            return <div>{fns.format(co.completed_at, 'dd LLL y', { locale: fns_locale.fr })}</div>
        },
    },
    {
        header: 'Volume collecté',
        accessorKey: 'volume',
        cell: ({ row }) => {
            const serviceData = row.original;
            if (typeof serviceData.informations.collect_config_id === 'string' || serviceData.informations.step_category !== PlanningShiftStepCategory.SERVICE) {
                return <MissingInformation />;
            }
            const cc = serviceData.informations.collect_config_id as CCServiceRo;

            const productsWithoutDelivery = serviceData?.informations?.collected_items?.filter(
                (p) => p.family !== PRODUCT_FAMILY.DELIVERY_BIG_BAG
            );
            const collectedVolume = displayVolumeFromProducts(productsWithoutDelivery, cc?.family);
            const volumeToCollect = displayVolumeFromProducts(cc?.products, cc?.family);

            return (
                <TooltipProvider>
                    <Tooltip delayDuration={200}>
                        <TooltipTrigger>
                            <div>
                                {collectedVolume} / {volumeToCollect}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className='py-2'>
                            <div className='flex flex-col gap-4 max-w-lg break-words'>
                                <div>
                                    <div className='font-bold'>Volume commandé</div>
                                    <div>
                                        {cc.products.map((p) => (
                                            <div key={p.id} className='mx-2'>
                                                {p.quantity} x {p.name} -{' '}
                                                {p.volume_m3}m3
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <div className='font-bold'>Volume réalisé</div>
                                    <div>
                                        {serviceData.status === CC_STATUS.FINISHED ? (
                                            <>
                                                {serviceData?.informations?.collected_items.map((p) => (
                                                    <div key={p.id} className='mx-2'>
                                                        {p.quantity} x {p.name} -{' '}
                                                        {p.volume_m3}m3
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <div className='mx-2'>
                                                Aucune collecte réalisée
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        },
    },
    {
        id: 'price',
        header: 'Prix',
        cell: ({ row }) => {
            const co = row.original;
            if (co.status !== CC_STATUS.FINISHED) {
                return <div>-</div>;
            }

            const info = co.informations;
            const presta = co.presta;

            if (!presta) return <MissingInformation />;

            const prestaProductRegistry = arrayToMap(presta.price_list, 'product_id');
            const productsTuples: Array<[ProductInCCOrCO, PrestaPrice]> = info.collected_items.map(
                (p) => [p, prestaProductRegistry[p.id] ?? null]
            );

            if (productsTuples.some(([, prd]) => !prd)) {
                return (
                    <div>
                        <Badge className='bg-red-400 hover:bg-red-500'>
                            Prix non défini
                        </Badge>
                    </div>
                );
            }

            const price = Dinero({
                amount: productsTuples.reduce((acc, [prd, prdPresta]) => {
                    return acc + prdPresta.price_in_centime * prd.quantity;
                }, 0),
                currency: info.price.discounted_net.currency,
            }).setLocale('fr-FR');

            return (
                <div>
                    {price.toFormat('$0,00')}
                </div>
            );
        },
    },
    {
        id: 'comment',
        cell: ({ row }) => {
            const co = row.original;
            if (typeof co.informations.collect_config_id === 'string') return <MissingInformation />;
            const cc = co.informations.collect_config_id;

            let msgCount = 0;

            if (cc.comment && cc.comment.length > 0) msgCount++;
            if (co.hazard_comment && co.hazard_comment.length > 0) msgCount++;

            return (
                <TooltipProvider>
                    <Tooltip delayDuration={200}>
                        {msgCount > 0 ? (
                            <TooltipTrigger>
                                <div className='flex items-center gap-2'>
                                    <span className='w-3 text-center'>
                                        {msgCount}
                                    </span>
                                    <MessageCircle strokeWidth={1} />
                                </div>
                            </TooltipTrigger>
                        ) : (
                            <div className='flex items-center gap-2'>
                                <span className='w-3 text-center'>
                                    {msgCount}
                                </span>
                                <MessageCircle strokeWidth={1} />
                            </div>
                        )}
                        <TooltipContent className='py-2'>
                            <div className='flex flex-col gap-4 max-w-lg break-words'>
                                {cc.comment && cc.comment.length > 0 && (
                                    <div>
                                        <div className='font-bold'>Commentaire de commande</div>
                                        <div className='px-4'>{cc.comment}</div>
                                    </div>
                                )}
                                {co.hazard_comment && co.hazard_comment.length > 0 && (
                                    <div>
                                        <div className='font-bold'>Commentaire d'aléa</div>
                                        <p className='px-4'>{co.hazard_comment}</p>
                                    </div>
                                )}
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        },
    },
    {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
            const co = row.original;
            if (typeof co.informations.collect_config_id === 'string') return <MissingInformation />;
            const cc = co.informations.collect_config_id;
            if (cc.family === CC_FAMILY.ADMINISTRATIVE) return <MissingInformation />;

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" size="sm">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onClick={() => {
                                cc.order_number && navigator.clipboard.writeText(cc.order_number);
                            }}
                        >
                            Copier le numéro de commande
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    }
];
