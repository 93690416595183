import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({});

export const queryKey = {
    login: {
        initiate: 'initiate-otp',
        validate: 'validate-otp',
    },
    user: {
        me: 'user-me',
    },
    cc: {
        infinite: 'cc-infinite',
        get: 'cc-get',
        retrieve: 'cc-retrieve',
        page: 'cc-page',
    },
    collect: {
        get: 'collect-get',
        page: 'collect-page',
        create: 'collect-create',
    },
    customer: {
        get: 'customer-get',
    },
    files: {
        upload: 'files-upload',
    },
} as const;
