import { useQuery } from "@tanstack/react-query";

import { httpClient } from "@/lib/axios";
import { ndlssEndpoints } from "@/lib/ndlssEndpoints";
import { queryKey } from "@/lib/query";
import { CCServiceRo, IBbngResponse } from "@/types/global";

export type UseCollectConfigProps = {
    id: string;
};

export const useCollectConfig = (props: UseCollectConfigProps) => {
    const query = useQuery({
        queryKey: [queryKey.cc.get, props.id] as const,
        queryFn: async (ctx) => {
            const id = ctx.queryKey[1];
            if (!id || id === '') throw new Error("L'identifiant de la collecte est manquant.");

            const response = await httpClient.get<IBbngResponse<CCServiceRo>>(ndlssEndpoints.collectConfigs.getById(id), {
                signal: ctx.signal,
            });

            return response.data;
        },
    });

    return query;
};

export type UseRetrieveCollectConfigsProps = {
    ids: string[];
};
export const UseRetrieveCollectConfigs = (props: UseRetrieveCollectConfigsProps) => {
    const query = useQuery({
        enabled: props.ids.length > 0,
        queryKey: [queryKey.cc.page, props.ids] as const,
        queryFn: async (ctx) => {
            const ids = ctx.queryKey[1];

            const response = await httpClient.post<IBbngResponse<CCServiceRo[]>>(ndlssEndpoints.collectConfigs.retrieve(), {
                ids,
            }, {
                signal: ctx.signal,
            });

            return response.data;
        },
    });

    return query;
};

