import React from "react";

import { useCollectConfigsListing } from "@/hooks/collect-configs";
import { fns } from "@/lib/fns";
import { CCServiceRo, CC_STATUS } from "@/types/global";
import { useFocusDrawer } from "@/hooks/drawers";

export const useToDo = () => {
    const ccQuery = useCollectConfigsListing({
        queryParams: {
            max_date: fns.endOfDay(new Date()).toISOString(),
            status: CC_STATUS.TO_PLAN,
        }
    })

    const collectConfigs = React.useMemo(() => {
        return ccQuery.data?.pages.flatMap((page) => page.data.ro ?? []) ?? [];
    }, [ccQuery.data?.pages]);

    const focusDrawer = useFocusDrawer<CCServiceRo>();

    return {
        query: ccQuery,
        data: collectConfigs,
        drawer: focusDrawer,
    };
};
