import { useQuery, type QueryFunction } from "@tanstack/react-query";

import { httpClient } from "@/lib/axios";
import { ndlssEndpoints } from "@/lib/ndlssEndpoints";
import { queryKey } from "@/lib/query";
import { IBbngResponse, type CollectQuery, type CollectRo } from "@/types/global";

export type UseCollectProps = {
    id: string;
};
export const useCollect = (props: UseCollectProps) => {
    const query = useQuery({
        queryKey: [queryKey.cc.get, props.id] as const,
        queryFn: async (ctx) => {
            const id = ctx.queryKey[1];
            if (!id || id === '') throw new Error("L'identifiant de la collecte est manquant.");

            const response = await httpClient.get<IBbngResponse<CollectRo>>(ndlssEndpoints.collects.getById(id), {
                signal: ctx.signal,
            });

            return response.data;
        },
    });

    return query;
};


/**
 * Fetches the collect services of a collect by page
 */
export type UseCollectsProps = {
    params: CollectQuery;
};
export const useCollects = (props: UseCollectsProps) => {
    const query = useQuery({
        queryKey: [queryKey.cc.page, props.params] as const,
        queryFn: useCollectsQueryFn,
    });

    return query;
};
export const useCollectsQueryFn: QueryFunction<IBbngResponse<CollectRo[]>, readonly ["cc-page", CollectQuery], never> = async (ctx) => {
    const params = ctx.queryKey[1];

    const response = await httpClient.get<IBbngResponse<CollectRo[]>>(ndlssEndpoints.collects.getAll(), {
        params,
        signal: ctx.signal,
    });

    return response.data;
};

