import { useMutation } from "@tanstack/react-query";

import { useAuth } from "@/contexts/auth";
import { queryKey } from "@/lib/query";

type UseInitiateOtpProps = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export const useInitiateOtp = (props: UseInitiateOtpProps = {}) => {
    const { startOtpSession } = useAuth();
    const mutation = useMutation({
        mutationKey: [queryKey.login.initiate],
        mutationFn: async (variables: { email: string }) => {
            const response = await startOtpSession(variables);

            if (response.success === false) {
                throw new Error(response.message);
            }

            return {
                success: response.success,
            };
        },
        onSuccess: () => {
            props.onSuccess?.();
        },
        onError: (error: Error) => {
            props.onError?.(error);
        },
    });

    return {
        mutate: mutation.mutate,
        isPending: mutation.isPending,
        isError: mutation.isError,
        isSuccess: mutation.isSuccess,
    };
};
