import { Currency } from 'dinero.js';

import { DocumentCreateDto } from '../document';
import { IKafkaQuery } from '../kafka';
import { BillingBranch, ETrashType, ISODate, MaybeArray } from '../misc';
import { ZoneRo } from '../zone';

export const PRODUCT_FAMILY = {
    DELIVERY_BIG_BAG           : 'DELIVERY_BIG_BAG',
    COLLECT_BIG_BAG            : 'COLLECT_BIG_BAG',
    COLLECT_DUMPSTER_DEPOSIT   : 'COLLECT_DUMPSTER_DEPOSIT',
    COLLECT_DUMPSTER_RETRIEVAL : 'COLLECT_DUMPSTER_RETRIEVAL',
    COLLECT_DUMPSTER_LOAD_WAIT : 'COLLECT_DUMPSTER_LOAD_WAIT'
} as const;
export type PRODUCT_FAMILY = typeof PRODUCT_FAMILY[keyof typeof PRODUCT_FAMILY];

export const PRODUCT_DUMPSTER_FAMILY = {
    COLLECT_DUMPSTER_DEPOSIT   : 'COLLECT_DUMPSTER_DEPOSIT',
    COLLECT_DUMPSTER_RETRIEVAL : 'COLLECT_DUMPSTER_RETRIEVAL',
    COLLECT_DUMPSTER_LOAD_WAIT : 'COLLECT_DUMPSTER_LOAD_WAIT'
} as const;
export type PRODUCT_DUMPSTER_FAMILY = typeof PRODUCT_DUMPSTER_FAMILY[keyof typeof PRODUCT_DUMPSTER_FAMILY];

export type ProductRo = {
    id: string;

    name: string;
    subname: string | null;

    description: string | null;

    internal: boolean; //Product that client can't buy or see;
    operational: boolean; //Product used for operations (always internal)
    is_default_empty_run: boolean; // Default empty run for the given volume/zone/family (the one used for automatic late cancelation fees)

    price: ProductPrice;
    vat_rate_percentage: number; //0 to 100
    family: PRODUCT_FAMILY;
    billing_branch: BillingBranch;

    trash_type: ETrashType;

    volume_m3: number;

    main_photo?: string; //id from s3

    photo_id: string[]; //ids from s3
    zone_id: string[];

    billing_modes: EProductBillingMode[]; // at least one

    archived: boolean;
    created_at: ISODate;
    updated_at: ISODate;
    created_by: string;
};

export type ProductRoFront = Omit<ProductRo, 'zone_id'> & {
    zone_id: string[] | ZoneRo[];
};

export type ProductPrice = {
    net_amount_cents: number;
    currency: Currency;
};

export type ProductQuery = IKafkaQuery &
    Partial<{
        trash_type: ETrashType;
        family: PRODUCT_FAMILY;
        families: PRODUCT_FAMILY[];
        billing_branch: BillingBranch;
        zone_id: MaybeArray<string>;
        internal: boolean;
        operational: boolean;
        q: string;
        billing_modes: EProductBillingMode[];
        volume_m3: number;
        is_default_empty_run: boolean;
    }>;

export type ProductCreateDto = {
    name: string;
    subname?: string;
    description?: string;
    internal: boolean; //Product that client can't buy or see;
    operational: boolean; //Product used for operations (always internal)
    price: ProductPrice;
    vat_rate_percentage: number; //0 to 100
    family: PRODUCT_FAMILY;
    billing_branch: BillingBranch;
    trash_type: ETrashType;
    volume_m3: number;
    main_photo?: DocumentCreateDto;
    photo_id?: DocumentCreateDto[];
    zone_id: string[];
    billing_modes: EProductBillingMode[]; // at least one
    is_default_empty_run: boolean;
};

export type ProductCreateActionDto = Omit<ProductCreateDto, 'main_photo' | 'photo_id'> & {
    id?: string;
    main_photo?: string;
    photo_id?: string[];
};
export type ProductUpdateActionDto = Partial<
    Omit<ProductCreateDto, 'main_photo' | 'photo_id'> & {
        main_photo?: string;
        photo_id?: string[];
        archived?: boolean;
    }
>;

export type ProductUpdateDto = Partial<Omit<ProductCreateDto, 'main_photo' | 'photo_id' | 'zone_id'>> & {
    main_photo?: {
        add?: DocumentCreateDto;
        remove: string;
    };
    photo_id?: {
        add: DocumentCreateDto[];
        remove: string[];
    };
    zone_id?: {
        add: string[];
        remove: string[];
    };
};

export type ProductSetVisibilityDto = {
    visibility: boolean;
};

export enum EProductBillingMode {
    INSTANT = 'INSTANT',
    DELAYED = 'DELAYED'
}

export type ProductGetCancelationAmountRo = ProductPrice & { isLate?: boolean };
