import React from "react";
import { useNavigate } from "react-router-dom";

import EndlessTypo from "@/assets/endless_typo.png";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Pages } from "@/lib/pages";

import { useLogin } from "./data";
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "@/components/ui/input-otp";
import { Helmet } from "react-helmet";
import { useAuth } from "@/contexts/auth";

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const { step, initiate, validate, reset, isPending } = useLogin({
        onLoginSuccess: () => {
            navigate(Pages.toDo.path(), { replace: true });
        },
    });
    const { isAuthenticated } = useAuth();

    React.useEffect(() => {
        if (isAuthenticated) {
            navigate(Pages.toDo.path(), { replace: true });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{Pages.login.title()}</title>
            </Helmet>
            <main className="h-screen flex flex-col justify-center items-center">
                <header className="flex flex-1 justify-center items-center">
                    <img src={EndlessTypo} alt="Endless" className="w-full max-w-lg px-20 sm:px-12" />
                </header>
                <div className="flex flex-col items-center gap-4 w-full">
                    {isPending ? (
                        <div className="flex flex-col items-center justify-center h-36 my-1">
                            <b className="text-2xl">
                                Chargement...
                            </b>
                        </div>
                    ) : (
                        step === 'initiate' ? (
                            <Form {...initiate.form}>
                                <form
                                    className="flex flex-col gap-4 px-10 w-full max-w-xl my-8"
                                    onSubmit={initiate.form.handleSubmit((data => {
                                        initiate.api.mutate(data);
                                    }))}
                                >
                                    <FormField
                                        control={initiate.form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        type="email"
                                                        placeholder="Entrez votre Email"
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <Button type="submit">SE CONNECTER</Button>
                                </form>
                            </Form>
                        ) : (
                            <Form {...validate.form}>
                                <form
                                    className="flex flex-col gap-4 px-10 w-full max-w-xl"
                                    onSubmit={validate.form.handleSubmit((data) => {
                                        validate.api.mutate({
                                            email: initiate.form.getValues('email'),
                                            code: data.code,
                                        })
                                    })}
                                >
                                    <div className="h-4 font-bold italic">
                                        {initiate.form.getValues('email')}
                                    </div>
                                    <FormField
                                        control={validate.form.control}
                                        name="code"
                                        render={({ field }) => (
                                            <FormItem className="self-center">
                                                <FormControl>
                                                    <InputOTP
                                                        maxLength={6}
                                                        render={({ slots }) => (
                                                            <>
                                                                <InputOTPGroup>
                                                                    {slots.slice(0, 3).map((slot, index) => (
                                                                        <InputOTPSlot key={index} {...slot} />
                                                                    ))}{" "}
                                                                </InputOTPGroup>
                                                                <InputOTPSeparator />
                                                                <InputOTPGroup>
                                                                    {slots.slice(3).map((slot, index) => (
                                                                        <InputOTPSlot key={index} {...slot} />
                                                                    ))}
                                                                </InputOTPGroup>
                                                            </>
                                                        )}
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <Button type="submit">VALIDER</Button>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="font-bold h-4 self-start p-0"
                                        onClick={reset}
                                    >
                                        <span className="text-xs underline">Email incorrect ?</span>
                                    </Button>
                                </form>
                            </Form>
                        )
                    )}
                </div>
                <footer className="flex-1" />
            </main>
        </>
    );
};
