import axios from "axios";
import { env } from "./env";
import { LocalStorage } from "./constant";
import React from "react";
import { useAuth } from "@/contexts/auth";
import { Sentry } from "./sentry";

export const httpClient = axios.create({
    baseURL: env.VITE_NDLSS_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

httpClient.interceptors.request.use((config) => {
    const token = localStorage.getItem(LocalStorage.accessToken);
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

httpClient.interceptors.request.use((config) => {
    config.params ??= {};
    if (config.params.sync === undefined) {
        config.params.sync = true;
    }

    return config;
});

// Sentry error handling
httpClient.interceptors.response.use(undefined, (error) => {
    if (axios.isAxiosError(error)) {
        Sentry.captureException(error);
    }

    return Promise.reject(error);
});

export const AxiosPrivateInterceptor: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { disconnect } = useAuth();

    React.useEffect(() => {
        const interceptor = httpClient.interceptors.response.use(undefined, (error) => {
            if (error.response?.status === 401 || error.response?.status === 403) {
                disconnect();
            }

            return Promise.reject(error);
        });

        return () => {
            httpClient.interceptors.response.eject(interceptor);
        };
    }, [disconnect]);

    return children;
};
