import { CaretSortIcon } from "@radix-ui/react-icons";
import React from "react";

import { CollectConfigZone, ETrashType, PRODUCT_DUMPSTER_FAMILY, PRODUCT_FAMILY, ProductInCCOrCO } from "@/types/global";
import { useDrawer } from "@/hooks/drawers";
import { useProducts } from "@/hooks/products";
import { humanizeProductFamily, humanizeTrashType } from "@/lib/humanize-map";
import { cn } from "@/lib/utils";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Sheet, SheetContent, SheetHeader, SheetFooter } from "@/components/ui/sheet";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { AddProductCard } from "@/components/product-card";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

type AddProductDrawerProps = {
    onAdd: (product: ProductInCCOrCO) => void;
    zone: CollectConfigZone;
};
export const AddProductDrawer: React.FC<AddProductDrawerProps> = ({ ...props }) => {
    const drawer = useDrawer();
    const productPopover = useDrawer();

    const [activeSection, setActiveSection] = React.useState<Sections>(Sections.PRODUCT_TYPE);

    const [productType, setProductType] = React.useState<ProductCategory | "">("");
    const [trashType, setTrashType] = React.useState<ETrashType | "">("");
    const [prestationType, setPrestationType] = React.useState<PRODUCT_DUMPSTER_FAMILY | "">("");
    const [product, setProduct] = React.useState<ProductInCCOrCO>();

    const productIsSelectable = productType !== "" && trashType !== "";

    const productsQuery = useProducts({
        params: {
            families: productCategoryToFamilies(productType, prestationType),
            trash_type: trashType === "" ? undefined : trashType,
            zone_id: props.zone.id,
        }
    });
    const productsGroups = React.useMemo<Array<{ family: PRODUCT_FAMILY; products: ProductInCCOrCO[] }>>(() => {
        const items = productsQuery.data?.data?.ro || [];
        if (items.length === 0) return [];

        const groups = items
            .sort((a, b) => {
                /**
                 * Display order:
                 * - public products
                 * - internal products
                 * - operational products
                 */
                if (a.operational && !b.operational) return 1;
                if (!a.operational && b.operational) return -1;
                if (a.internal && !b.internal) return 1;
                if (!a.internal && b.internal) return -1;

                /**
                 * Sort by ascending volume.
                 */
                return a.volume_m3 - b.volume_m3;
            })
            .reduce((acc, item) => {
            if (!acc[item.family]) {
                acc[item.family] = [];
            }
            acc[item.family].push({
                id: item.id,
                billing_branch: item.billing_branch,
                description: item.description,
                family: item.family,
                internal: item.internal,
                name: item.name,
                operational: item.operational,
                price: item.price,
                quantity: 1,
                subname: item.subname,
                trash_type: item.trash_type,
                vat_rate_percentage: item.vat_rate_percentage,
                volume_m3: item.volume_m3,
                main_photo: item.main_photo,
                zone: props.zone,
            });
            return acc;
        }, {} as Record<PRODUCT_FAMILY, ProductInCCOrCO[]>);

        return Object.entries(groups).map(([group, products]) => ({ family: group as PRODUCT_FAMILY, products }));
    }, [productsQuery.data, props.zone])

    React.useEffect(() => {
        if (drawer.isOpen) {
            setProductType("");
            setPrestationType("");
            setTrashType("");
            setProduct(undefined);
            setActiveSection(Sections.PRODUCT_TYPE);
        }
    }, [drawer.isOpen])

    return (
        <>
            <AddProductCard onClick={drawer.open} />
            <Sheet open={drawer.isOpen} onOpenChange={(open) => open ? drawer.open() : drawer.close()}>
                <SheetContent onInteractOutside={drawer.close} className="flex flex-col h-full">
                    <SheetHeader>
                        <h2 className="text-xl font-bold mb-2">Ajouter un produit</h2>
                    </SheetHeader>
                    <div className="flex flex-col gap-2">
                        <Accordion collapsible type="single" value={activeSection} onValueChange={(e: Sections) => setActiveSection(e)}>

                            <AccordionItem value={Sections.PRODUCT_TYPE}>
                                <AccordionTrigger>
                                    Type de produits {productType ? `: ${productType}` : ""}
                                </AccordionTrigger>
                                <AccordionContent>
                                    <section className="flex flex-col items-stretch gap-1">
                                        <Select
                                            value={productType}
                                            onValueChange={(value: ProductCategory) => {
                                                setProductType(value);
                                                setPrestationType("");
                                                setTrashType("");
                                                if (value === ProductCategory.DUMPSTER) {
                                                    setActiveSection(Sections.PRESTATION_TYPE);
                                                } else {
                                                    setActiveSection(Sections.TRASH_TYPE);
                                                }
                                            }}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Sélectionnez un type de produit" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {Object.values(ProductCategory).map(cat => (
                                                        <SelectItem
                                                            key={cat}
                                                            value={cat}
                                                        >
                                                            {cat}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </section>
                                </AccordionContent>
                            </AccordionItem>

                            {
                                productType === ProductCategory.DUMPSTER &&
                                <AccordionItem value={Sections.PRESTATION_TYPE}>
                                    <AccordionTrigger>
                                        Type de prestation {prestationType ? `: ${humanizeProductFamily(prestationType)}` : ""}
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        <section className="flex flex-col items-stretch gap-1">
                                            <Select
                                                value={prestationType}
                                                onValueChange={(value: PRODUCT_DUMPSTER_FAMILY) => {
                                                    setPrestationType(value);
                                                    setActiveSection(Sections.TRASH_TYPE);
                                                }}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Sélectionnez un type de prestation" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {Object.values(PRODUCT_DUMPSTER_FAMILY).map(type => (
                                                            <SelectItem
                                                                key={type}
                                                                value={type}
                                                            >
                                                                {humanizeProductFamily(type)}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </section>
                                    </AccordionContent>
                                </AccordionItem>
                            }

                            <AccordionItem value={Sections.TRASH_TYPE}>
                                <AccordionTrigger>
                                    Type de déchets {trashType ? `: ${humanizeTrashType(trashType)}` : ""}
                                </AccordionTrigger>
                                <AccordionContent>
                                    <section className="flex flex-col items-stretch gap-1">
                                        <Select
                                            value={trashType}
                                            onValueChange={(value: ETrashType) => {
                                                setTrashType(value);
                                                setActiveSection(Sections.PRODUCT);
                                            }}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Sélectionnez un type de déchet" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {Object.values(ETrashType).map(type => (
                                                        <SelectItem
                                                            key={type}
                                                            value={type}
                                                        >
                                                            {humanizeTrashType(type)}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </section>
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value={Sections.PRODUCT}>
                                <AccordionTrigger>
                                    Produit
                                </AccordionTrigger>
                                <AccordionContent>
                                    <section className="flex flex-col items-stretch gap-1">
                                        {product && (
                                            <Card className="p-4 font-bold items-center text-center">
                                                <p>
                                                    {product.name}
                                                </p>
                                            </Card>
                                        )}
                                        {productIsSelectable ? (
                                            <Popover
                                                open={productPopover.isOpen}
                                                onOpenChange={e => e ? productPopover.open() : productPopover.close()}
                                            >
                                                <PopoverTrigger asChild disabled={productsQuery.isFetching || productsGroups.length === 0}>
                                                    <Button
                                                        disabled={productsQuery.isFetching || productsGroups.length === 0}
                                                        variant="outline"
                                                        className={cn(
                                                            "w-[240px] pl-3 text-left font-normal self-center",
                                                            !product && "text-muted-foreground",
                                                            product && "my-2"
                                                        )}
                                                    >
                                                        {productsQuery.isFetching
                                                            ? "Chargement..."
                                                            : productsGroups.length === 0
                                                                ? "Aucun produit disponible"
                                                                : (
                                                            <>
                                                                {product
                                                                    ? "Changer de produit"
                                                                    : "Sélectionnez un produit"
                                                                }
                                                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />

                                                            </>
                                                        )}
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-[300px] p-0">
                                                    {productsQuery.isSuccess && (
                                                        <Command>
                                                            <CommandInput placeholder="Rechercher un produit..." className="h-9" />
                                                            <CommandList className="m-h-auto">
                                                                <CommandEmpty>Aucun produit trouvé.</CommandEmpty>
                                                                {/* <ScrollArea className="h-80"> */}
                                                                    {productsGroups.map((elem) => (
                                                                        <CommandGroup heading={humanizeProductFamily(elem.family)} key={elem.family}>
                                                                            {elem.products.map(product => (
                                                                                <CommandItem
                                                                                    key={product.id}
                                                                                    value={product.name} // for search
                                                                                    onSelect={() => {
                                                                                        const products = productsGroups.flatMap(g => g.products);
                                                                                        const prd = products.find(p => p.id === product.id);
                                                                                        setProduct(prd);
                                                                                        productPopover.close();
                                                                                    }}
                                                                                >
                                                                                    {product.name}
                                                                                </CommandItem>
                                                                            ))}
                                                                        </CommandGroup>
                                                                    ))}
                                                                {/* </ScrollArea> */}
                                                            </CommandList>
                                                        </Command>
                                                    )}
                                                </PopoverContent>
                                            </Popover>
                                        ) : (
                                            <p className="italic text-slate-500 text-sm text-justify">
                                                Veuillez sélectionner un type de produit et un type de déchet
                                            </p>
                                        )}
                                    </section>
                                </AccordionContent>
                            </AccordionItem>

                            {product && (
                                <Button
                                    className="w-full"
                                    onClick={() => {
                                        props.onAdd(product);
                                        drawer.close();
                                    }}
                                >
                                    Ajouter
                                </Button>
                            )}

                        </Accordion>
                    </div>
                    <div className="flex-1" />
                    <SheetFooter>
                        <Button
                            variant={"outline"}
                            disabled={!drawer.isOpen}
                            onClick={() => {
                                drawer.close();
                            }}
                        >
                            Fermer
                        </Button>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        </>
    );
};

const ProductCategory = {
    DUMPSTER: "Benne",
    BIG_BAG: "Big Bag",
    DELIVERY: "Livraison"
} as const;
type ProductCategory = typeof ProductCategory[keyof typeof ProductCategory];

const Sections = {
    PRODUCT_TYPE: "PRODUCT_TYPE",
    PRESTATION_TYPE: "PRESTATION_TYPE",
    TRASH_TYPE: "TRASH_TYPE",
    PRODUCT: "PRODUCT"
} as const;
type Sections = typeof Sections[keyof typeof Sections];

const productCategoryToFamilies = (cat: ProductCategory | "", presta: PRODUCT_DUMPSTER_FAMILY | "") => {
    switch (cat) {
        case ProductCategory.DUMPSTER: {
            if (presta) {
                return [presta];
            } else {
                return [
                    PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT,
                    PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL,
                    PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT
                ];
            }
        }
        case ProductCategory.BIG_BAG:
            return [PRODUCT_FAMILY.COLLECT_BIG_BAG];
        case ProductCategory.DELIVERY:
            return [PRODUCT_FAMILY.DELIVERY_BIG_BAG];
        case "":
        default:
            return [];
    }
}
