import { ProductInCCOrCO } from "@/types/global";
import { Card } from "./ui/card";
import { Edit, PlusSquare } from "lucide-react";
import React from "react";
import { useProduct } from "@/hooks/products";
import { Skeleton } from "./ui/skeleton";
import { cn } from "@/lib/utils";
import { Button } from "./ui/button";

export type ProductCardProps = {
    productId: string;
    quantity: number;
    product: ProductInCCOrCO;
    onClick?: () => void;
};
export const ProductCard: React.FC<ProductCardProps> = ({ ...props }) => {
    const query = useProduct({ id: props.productId });

    const product = query.data?.data.ro;

    return (
        <Card
            onClick={() => {
                if (query.isSuccess) {
                    props.onClick?.();
                }
            }}
            className={cn(
                "flex justify-between items-center p-4",
                query.isSuccess ? "cursor-pointer" : ""
            )}
        >
            <div>
                {query.isSuccess && product ? (
                    product.name
                ) : (
                    <Skeleton className="w-40 md:w-60 h-5" />
                )}
            </div>
            <div className="flex items-center gap-6">
                <div>
                    x {props.quantity}
                </div>
                <Edit
                    size="20px"
                    className={cn(
                        query.isSuccess ? "" : "opacity-30"
                    )}
                />
            </div>
        </Card>
    );
}

export const ProductCardSkeleton: React.FC = () => {
    return (
        <Card className="flex justify-between items-center p-4 cursor-pointer border-dashed border-slate-400">
            <div>
                <div className="w-24 h-4 bg-slate-400 animate-pulse rounded-md" />
            </div>
        </Card>
    );
};

type AddProductCardProps = {
    onClick?: () => void;
};
export const AddProductCard: React.FC<AddProductCardProps> = (props) => {
    return (
        <Button type="button" onClick={props.onClick} variant={"outline"} className="w-fit">
            <div>
                <PlusSquare size="20px" className="mr-2"/>
            </div>
            <div>
                Ajouter un produit
            </div>
        </Button>
    );
};
