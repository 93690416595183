import * as envalid from 'envalid';

export const env = envalid.cleanEnv(import.meta.env, {
    VITE_NDLSS_API_URL: envalid.url(),
    VITE_APP_VERSION: envalid.str({ example: '1.0.0' }),
    VITE_SENTRY_DSN: envalid.url(),
    VITE_SENTRY_ENVIRONMENT: envalid.str({ example: 'dev/stg/prd'}),
    VITE_SENTRY_DEBUG: envalid.bool({ default: false }),
}, {
    reporter: ({ errors }) => {
        if (Object.keys(errors).length === 0) return;

        console.error('The following environment variables are required but not provided:', errors);
        throw new Error('Missing environment variables');
    }
});
