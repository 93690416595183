import type { ColumnDef, PaginationState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

import { DataTableViewOptions } from './datatable-view-options';
import { DataTablePagination } from './datatable-pagination';

type DataTableProperties<TData, TValue> = {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	totalCount: number;
	setPagination: (data: PaginationState) => void;
	pagination: PaginationState;
	displaySelectLinePagination?: boolean;
	displayPaginationComponent?: boolean;
	isFetching?: boolean;
	headDataTable?: JSX.Element;
	meta?: Record<string, any>;
};

export function DataTable<TData, TValue>({
	columns,
	data,
	totalCount,
	setPagination,
	pagination,
	displayPaginationComponent = true,
	isFetching,
	meta,
	headDataTable,
}: DataTableProperties<TData, TValue>) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onPaginationChange: (data) => {
			if (typeof data === 'function') {
				return setPagination(data(pagination));
			}
			setPagination(data);
		},
		state: {
			pagination,
		},
		meta,
		manualPagination: true,
		pageCount: Math.ceil(totalCount / pagination.pageSize),
	});

	const displayTable = () => {
		return (
			<div>
				<div className='flex items-end justify-between'>
					<div className='flex flex-1 items-center space-x-2'>
						{headDataTable}
					</div>
					<DataTableViewOptions table={table} />
				</div>
				<div className="my-3 rounded-md border">
					<Table>
						<TableHeader>
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<TableHead key={header.id}>
												{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
											</TableHead>
										);
									})}
								</TableRow>
							))}
						</TableHeader>
						<TableBody>
							{table.getRowModel().rows?.length ? (
								table.getRowModel().rows.map((row) => (
									<TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
										{row.getVisibleCells().map((cell) => (
											<TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
										))}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={columns.length} className="h-24 text-center">
										{isFetching ? 'Chargement...' : 'Pas de résultats.'}
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			</div>
		);
	};

	const displayPagination = () => {
		if (!displayPaginationComponent) {
			return null;
		}
		return <DataTablePagination isFetching={isFetching} table={table} totalCount={totalCount} />;
	};

	return (
		<>
			{displayTable()}
			{displayPagination()}
		</>
	);
}
