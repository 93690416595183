import { CCServiceRo, CollectCreateServiceDto, ECollectHazardReason, EDocumentType, PlanningShiftStepCategory } from "@/types/global";
import { ReportCCFieldValues } from "./form";
import { fns } from "@/lib/fns";
import { NdlssFile } from "@/hooks/files";

type ReportCCOutputMappingProps = {
    values: ReportCCFieldValues;
    cc: CCServiceRo;
};
export const outputMapping = async ({ values, cc }: ReportCCOutputMappingProps): Promise<{ dto: CollectCreateServiceDto, files: NdlssFile[] }> => {
    const day = fns.setHours(fns.setMinutes(values.scheduled_at, 0), 12);
    const started_at = fns.toDate(fns.toDate(day).setHours(fns.getHours(new Date(values.started_at)), fns.getMinutes(new Date(values.started_at))));
    const ended_at = fns.toDate(fns.toDate(day).setHours(fns.getHours(new Date(values.ended_at)), fns.getMinutes(new Date(values.ended_at))));

    const files: NdlssFile[] = values.files.map((file, idx) => ({
        file: file,
        type: EDocumentType.COLLECT_PHOTO,
        name: `${cc.number}_image_${idx}`,
        customKey: `${cc.order_number}|${cc.number}`,
    }));

    return {
        dto: {
            category: PlanningShiftStepCategory.SERVICE,
            region: cc.region,
            type: cc.type,
            day: day.toISOString(),
            arrived_at: started_at.toISOString(),
            completed_at: ended_at.toISOString(),
            collect_config_id: cc.id,
            is_splitted: false,
            step_service: {
                category               : PlanningShiftStepCategory.SERVICE,
                collect_config_id      : cc.id,
                customer_id            : cc.customer_id[0],
                is_splitted            : false,
                scheduled_at           : started_at.toISOString(),
                scheduled_end_at       : ended_at.toISOString(),
                scheduled_service_time : '00:00',
            },
            was_planned: true,
            ...(values.is_finished ? {
                status: 'FINISHED',
                collected_items: values.cart.map(item => ({ id: item.id, quantity: item.quantity })),
            } : {
                collected_items: [],
                hazard_reason: values.hazard_reason as ECollectHazardReason,
                hazard_comment: values.hazard_comment ?? '',
                status: 'HAZARD',
            }),
            // delivery_number: '', // TODO: Implement delivery number
            // dumpster_weight: 0, // TODO: Implement dumpster weight
        },
        files: files,
    };
};
