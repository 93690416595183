import React from "react";

import { CC_FAMILY } from "@/types/global";
import { cn } from "@/lib/utils";
import { humanizeCcFamily } from "@/lib/humanize-map";
import { Badge } from "./ui/badge";

export type BadgeColors = Record<CC_FAMILY, React.HTMLAttributes<HTMLDivElement>['className']>;

export type CCBadgeCategoryProps = {
    family: CC_FAMILY;
    overrideColors?: Partial<BadgeColors>;
};

const colors: BadgeColors = {
    [CC_FAMILY.ADMINISTRATIVE]: 'bg-gray-200 text-black',
    [CC_FAMILY.COLLECT_BIG_BAG]: 'bg-blue-500 text-white',
    [CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT]: 'bg-green-500 text-white',
    [CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT]: 'bg-yellow-500 text-white',
    [CC_FAMILY.COLLECT_DUMPSTER_ROTATION]: 'bg-orange-500 text-white',
    [CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL]: 'bg-lime-500 text-white',
    [CC_FAMILY.DELIVERY_BIG_BAG]: 'bg-purple-500 text-white',
}

export const CCBadgeCategory = (props: CCBadgeCategoryProps) => {
    const badgeColors: BadgeColors = React.useMemo(() => {
        return {
            ...colors,
            ...props.overrideColors,
        };
    }, [props.overrideColors]);

    return (
        <Badge className={cn(
            badgeColors[props.family]
        )}>
            {humanizeCcFamily(props.family)}
        </Badge>
);
};
