import React from "react";
import { CalendarCheck, CalendarDays, ChevronRight, History, LogOut, Menu } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { Link } from "react-router-dom";

import EndlessTypo from "@/assets/endless_typo.png";
import EndlessCircleLogo from "@/assets/endless-circle-logo.png";
import { useUser } from "@/contexts/user";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useAuth } from "@/contexts/auth";
import { useDrawer } from "@/hooks/drawers";
import { Sheet, SheetContent, SheetFooter, SheetHeader } from "@/components/ui/sheet";
import { Pages } from "@/lib/pages";
import { cn } from "@/lib/utils";

export const Header: React.FC = () => {
    const auth = useAuth();
    const user = useUser();
    const burgerMenu = useDrawer();

    return (
        <header className="sticky top-0 min-h-12 p-4 flex justify-between items-center backdrop-blur-sm shadow-md z-10">
            <Button variant="ghost" size="icon" onClick={burgerMenu.open}>
                <Menu />
            </Button>
            <Link to="/">
                <img src={EndlessTypo} alt="Endless" className="h-6" />
            </Link>
            <Popover>
                <PopoverTrigger asChild>
                    <Button variant="ghost" size={"icon"}>
                        <Avatar>
                            <AvatarFallback>
                                {user.ro ? user.ro.name?.[0] ?? '?' : '?'}
                            </AvatarFallback>
                        </Avatar>
                    </Button>
                </PopoverTrigger>
                <PopoverContent side="left" sideOffset={10}>
                    <Card className="">
                        <Button variant="outline" onClick={auth.disconnect}>
                            Se déconnecter
                            <LogOut size="16px" className="ml-2" />
                        </Button>
                    </Card>
                </PopoverContent>
            </Popover>
            <Sheet
                open={burgerMenu.isOpen}
                onOpenChange={e => !e ? burgerMenu.close() : void (0)}>
                <SheetContent
                    side={"left"}
                    onInteractOutside={burgerMenu.close}
                    className="flex flex-col"
                    onOpenAutoFocus={e => e.preventDefault()}
                >
                    <SheetHeader className="mb-4">
                        <div className="flex items-center">
                            <img src={EndlessCircleLogo} alt="Endless" className="h-[2.5rem]" />
                            <h1 className="text-xl font-bold ml-2">EndLess Prestataire</h1>
                        </div>
                    </SheetHeader>
                    <div className="flex-1 flex flex-col gap-8">
                        <nav className="flex flex-col gap-4">
                            <Link to={Pages.toDo.path()} className="flex items-center" onClick={burgerMenu.close} autoFocus>
                                <CalendarCheck />
                                <p className="pl-2 flex-1">
                                    {Pages.toDo.title()}
                                </p>
                                <ChevronRight />
                            </Link>
                            <Link to={Pages.toCome.path()} className="flex items-center" onClick={burgerMenu.close}>
                                <CalendarDays />
                                <p className="pl-2 flex-1">
                                    {Pages.toCome.title()}
                                </p>
                                <ChevronRight />
                            </Link>
                            <Link to={Pages.history.path()} className="flex items-center" onClick={burgerMenu.close}>
                                <History />
                                <p className="pl-2 flex-1">
                                    {Pages.history.title()}
                                </p>
                                <ChevronRight />
                            </Link>
                        </nav>
                    </div>
                    <SheetFooter>
                        <div
                            className={cn(
                                "w-full flex items-center gap-2",
                                !user.ro && "animate-pulse"
                            )}
                        >
                            <Avatar>
                                <AvatarFallback>
                                    {user.ro ? user.ro.name?.[0] ?? '?' : '?'}
                                </AvatarFallback>
                            </Avatar>
                            <div>
                                {user.ro
                                    ? (
                                        <>
                                            <p className="font-bold">{user.ro.name}</p>
                                            <p className="text-sm text-gray-500">{user.ro.email}</p>
                                        </>
                                    )
                                    : (
                                        <>
                                            <div className="h-3 w-20 mb-2 bg-gray-300 rounded-full" />
                                            <div className="h-3 w-32 bg-gray-300 rounded-full" />
                                        </>
                                    )
                                }
                            </div>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button variant={"link"} className="ml-auto justify-self-end self-start p-0">
                                        <LogOut />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent side="top" sideOffset={10}>
                                    <Card className="">
                                        <Button variant="outline" onClick={auth.disconnect}>
                                            Se déconnecter
                                        </Button>
                                    </Card>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        </header>
    );
};
