import { Navigate, Outlet } from "react-router-dom";

import { Header } from "@/components/header";
import { useAuth } from "@/contexts/auth";
import { UserProvider } from "@/contexts/user";
import { Pages } from "@/lib/pages";
import { Footer } from "@/components/footer";
import { AxiosPrivateInterceptor } from "@/lib/axios";

export const Private = () => {
    const { isAuthenticated, hydrated } = useAuth();

    if (!hydrated) {
        return <div />;
    }

    if (!isAuthenticated) {
        return <Navigate to={Pages.login.path()} />;
    }

    return (
        <AxiosPrivateInterceptor>
            <UserProvider>
                <Header />
                <Outlet />
                <Footer />
            </UserProvider>
        </AxiosPrivateInterceptor>
    );
}
