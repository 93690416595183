import React from "react";

import { useCollectConfigsListing } from "@/hooks/collect-configs";
import { fns } from "@/lib/fns";
import { CCServiceRo, CC_STATUS } from "@/types/global";

export const useToCome = () => {
    const ccQuery = useCollectConfigsListing({
        queryParams: {
            min_date: fns.startOfDay(fns.addDays(new Date(), 1)).toISOString(),
            status: CC_STATUS.TO_PLAN,
        }
    });

    const collectConfigs = React.useMemo(() => {
        return ccQuery.data?.pages.flatMap((page) => page.data.ro ?? []) ?? [];
    }, [ccQuery.data?.pages]);

    const [focusedCC, setFocusedCC] = React.useState<CCServiceRo | undefined>(undefined);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const focusOnCc = React.useCallback((cc: CCServiceRo) => {
        setFocusedCC(cc);
        setIsDrawerOpen(true);
    }, [setFocusedCC, setIsDrawerOpen]);

    return {
        query: ccQuery,
        data: collectConfigs,
        drawer: {
            focusedCC,
            focusOn: focusOnCc,
            isOpen: isDrawerOpen,
            close: () => setIsDrawerOpen(false),
        }
    };
};
