import React, { PropsWithChildren } from 'react';
import * as fns from 'date-fns';
import axios from 'axios';

import { IBbngResponse, AccessRo, CollectorRo } from '@/types/global';
import { ndlssEndpoints } from '@/lib/ndlssEndpoints';
import { env } from '@/lib/env';
import { LocalStorage } from '@/lib/constant';

type AuthContextStates = {
    hydrated: boolean;
    isAuthenticated: boolean;
};

type AuthContextMethods = {
    startOtpSession: (props: { email: string; }) => Promise<{ success: boolean; message?: string }>;
    completeOtpSession: (props: { email: string; code: string }) => Promise<{ success: boolean; message?: string }>;
    disconnect: () => Promise<void>;
};

export type AuthContextType = AuthContextStates & AuthContextMethods;

const defaultAuthContext: AuthContextType = {
    hydrated: false,
    isAuthenticated: false,
    startOtpSession: async () => { throw new Error('AuthContext not initialized'); },
    completeOtpSession: async () => { throw new Error('AuthContext not initialized'); },
    disconnect: async () => { throw new Error('AuthContext not initialized'); },
};

const AuthContext = React.createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [states, setStates] = React.useState<AuthContextStates>(defaultAuthContext);

    React.useEffect(() => {
        const accessToken = localStorage.getItem(LocalStorage.accessToken);
        const expireAt = localStorage.getItem(LocalStorage.expireAt);

        if (accessToken && expireAt) {
            const expireAtDate = new Date(expireAt);

            if (expireAtDate > new Date()) {
                setStates((prev) => ({
                    ...prev,
                    isAuthenticated: true,
                }));
            } else {
                localStorage.removeItem(LocalStorage.accessToken);
                localStorage.removeItem(LocalStorage.expireAt);
            }
        }

        setStates((prev) => ({
            ...prev,
            hydrated: true,
        }));
    }, []);

    const startOtpSession = React.useCallback<AuthContextMethods['startOtpSession']>(async (props) => {
        const res = await axios.post<IBbngResponse>(ndlssEndpoints.auth.startOtpSession(), {
            email: props.email,
        }, {
            baseURL: env.VITE_NDLSS_API_URL,
            validateStatus: () => true
        });

        return {
            success: res.status === 200,
            message: res.data?.data?.message,
        };
    }, []);

    const completeOtpSession = React.useCallback<AuthContextMethods['completeOtpSession']>(async (props) => {
        const res = await axios.post<IBbngResponse<AccessRo<CollectorRo>>>(ndlssEndpoints.auth.validateOtpSession(), {
            email: props.email,
            code: props.code,
        }, {
            baseURL: env.VITE_NDLSS_API_URL,
            validateStatus: () => true
        });

        if (res.status === 200 && res.data && res.data.data?.ro) {
            const data = res.data.data.ro;

            localStorage.setItem(LocalStorage.accessToken, data.access_token);
            localStorage.setItem(LocalStorage.expireAt, fns.addSeconds(new Date(), data.expires_in).toISOString());

            setStates((prev) => ({
                ...prev,
                isAuthenticated: true,
            }));
        }

        return {
            success: res.status === 200,
            message: res.data?.data?.message,
        };
    }, []);

    const disconnect = React.useCallback<AuthContextMethods['disconnect']>(async () => {
        const sessionToken = localStorage.getItem(LocalStorage.accessToken);
        localStorage.removeItem(LocalStorage.accessToken);
        localStorage.removeItem(LocalStorage.expireAt);

        if (sessionToken) {
            axios.get<IBbngResponse<AccessRo<CollectorRo>>>(ndlssEndpoints.auth.closeSession(), {
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                },
                baseURL: env.VITE_NDLSS_API_URL,
                validateStatus: () => true
            }).catch(console.error);
        }

        setStates((prev) => ({
            ...prev,
            isAuthenticated: false,
        }));
    }, []);

    const value = React.useMemo<AuthContextType>(() => ({
        ...states,
        startOtpSession,
        completeOtpSession,
        disconnect,
    }), [states, startOtpSession, completeOtpSession, disconnect]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);
