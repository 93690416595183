import React from "react";
import { Link } from "react-router-dom";
import { Copy, User } from "lucide-react";
import { ErrorBoundary } from "react-error-boundary";

import { CCServiceRo } from "@/types/global";
import { Button } from "@/components/ui/button";
import { CCBadgeCategory } from "./cc-badge-category";

export type CCInfoProps = {
    cc: CCServiceRo
};

export const CCInfo: React.FC<CCInfoProps> = ({ cc }) => {
    return (
        <div>
            <ErrorBoundary FallbackComponent={() => <div>Une erreur est survenue</div>}>
                <CCMissionInfo cc={cc} />
                <CCCustomerInfo cc={cc} />
                <CCOrderInfo cc={cc} />
            </ErrorBoundary>
        </div>
    );
};

export const CCMissionInfo: React.FC<{ cc: CCServiceRo }> = ({ cc }) => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${cc.address.coordinates.latitude}%2C${cc.address.coordinates.longitude}`;

    return (
        <section id="cc-mission-info">
            <h2 className="text-lg font-bold">Information de mission</h2>
            <div className="pl-2">
                <CCBadgeCategory family={cc.family} />
                <p><small>Commande: {cc.order_number}</small></p>
                <p><small>Adresse de mission</small></p>
                <div className="pl-2 border-l-2 font-bold underline underline-offset-2 mb-1">
                    <Link to={mapsUrl} target="_blank" rel="noopener noreferrer">
                        <small>{cc.address.formatted_name}</small>
                    </Link>
                    <Button size={"icon"} variant={'outline'} onClick={() => navigator.clipboard.writeText(cc.address.formatted_name)} className="ml-2">
                        <Copy size="1rem" />
                    </Button>
                </div>
                {cc.comment && cc.comment !== '' && (
                    <div className="mt-1">
                        <p><small>Commentaire</small></p>
                        <div className="pl-2 border-l-2">
                            <small>{cc.comment}</small>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export const CCCustomerInfo: React.FC<{ cc: CCServiceRo }> = ({ cc }) => {
    return (
        <section id="cc-customer-info">
            <h2 className="text-lg font-bold mt-2">Information client</h2>
            <div className="pl-2">
                <p><small>Contact Chantier</small></p>
                {cc.construction_site_contact.length > 0 ? (
                    <div className="pl-2 border-l-2">
                        {cc.construction_site_contact.map((contact, index) => {
                            const name = [contact.firstname, contact.lastname].filter(Boolean).join(' ');
                            const phone = contact.phone_number;
                            return (
                                <div key={index + contact.phone_number} className={"flex items-center gap-2"}>
                                    <User size="1rem" />
                                    <p><small>-</small></p>
                                    <p><small>{name}</small></p>
                                    <Link to={`tel:${phone}`} className="font-bold underline underline-offset-2"><small>{phone}</small></Link>
                                    <Button size={"icon"} variant={'outline'} onClick={() => navigator.clipboard.writeText(phone)}>
                                        <Copy size="1rem" className="ml-1" />
                                    </Button>
                                </div>
                            );
                        })}
                        <p></p>
                    </div>
                ) : (
                    <p className="pl-2 border-l-2 italic"><small>Aucun contact renseigné</small></p>
                )}
            </div>
        </section>
    );
};

export const CCOrderInfo: React.FC<{ cc: CCServiceRo }> = ({ cc }) => {
    return (
        <section id="cc-order-info">
            <h2 className="text-lg font-bold mt-2">Commande client</h2>
            <div className="pl-2">
                {cc.products.map((product, index) => {
                    return (
                        <div key={index} className="flex items-center gap-2 text-sm">
                            <p>{product.quantity}</p>
                            <p>x</p>
                            <p>{product.name}</p>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};
